import React from "react";
import useTranslation from "../hooks/useTranslation";
import { Link } from "react-router-dom";
import { Pagination } from "../__generated__/graphql";
import URI from "urijs";

const PaginationComponent = ({ pagination, currentUri }: { pagination: Pagination; currentUri: URI }) => {
  const { t } = useTranslation();
  const { page, pages } = pagination;

  const prevUri = currentUri.clone().setSearch({ page: page - 1 });
  const nextUri = currentUri.clone().setSearch({ page: page + 1 });

  return (
    <div className="pagination">
      <ul className="naviguator">
        {page > 1 && (
          <li>
            <Link to={prevUri.toString()} className="previous">
              <span className="paginationWords">{t("previous")}</span>
            </Link>
          </li>
        )}
        <li>
          <p className="pageCount">
            {t("page")} {page} {t("of")} {pages}
          </p>
        </li>
        {page < pages && (
          <li>
            <Link to={nextUri.toString()} className="next">
              <span className="paginationWords">{t("next")}</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default PaginationComponent;
