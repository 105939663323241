import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { findUrlForMediaArray } from "../utils";
import { Store } from "../store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArticleContentBlock, Theme } from "../__generated__/graphql";

export default function Carousel({ block }: { block: ArticleContentBlock }) {
  const theme = Store.useState(s => s.theme) as Theme;

  const transitionType = theme.settings?.carousel?.transitionType;
  const speed = theme.settings?.carousel?.transitionSpeed;
  const autoplay = theme.settings?.carousel?.autoplay;
  const autoplaySpeed = theme.settings?.carousel?.speed;
  const dots = theme.settings?.carousel?.dots;

  const slickSettings = {
    dots: (dots === undefined ? true : dots) || false,
    fade: transitionType === "fade",
    infinite: true,
    speed: speed || 1000,
    autoplaySpeed: autoplaySpeed || 3000,
    autoplay: (autoplay === undefined ? true : autoplay) || false,
    lazyload: "ondemand",
    slidesToShow: 1,
    arrows: false,
    draggable: true,
    slidesToScroll: 1,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1080,
        appendDots: null,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          draggable: true
        }
      }
    ]
  };
  const entries: { src: string; to?: string }[] = block.data.entries.map((e: any) => ({
    src: findUrlForMediaArray(e.media, e.format),
    to: e.to
  }));

  return (
    <div className="carouselContainer">
      <Slider {...slickSettings}>
        {entries.map((e, i) => (
          <React.Fragment key={i}>
            {e.to && typeof e.to === "string" ? (
              <>
                {e.to.includes("http") ? (
                  <a href={e.to} target="_blank" rel="noopener noreferrer">
                    <img key={e.src} src={e.src} alt={`Carousel entry ${i}`} />
                  </a>
                ) : (
                  <Link to={e.to}>
                    <img key={e.src} src={e.src} alt={`Carousel entry ${i}`} />
                  </Link>
                )}
              </>
            ) : (
              <img key={e.src} src={e.src} alt={`Carousel entry ${i}`} />
            )}
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
}
