import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import URI from "urijs";
import { Tile } from "../components/views";
import Loader from "../components/loader";
import { Store } from "../store";
// import Cookies from "js-cookie";
import { useInventory } from "../hooks/useInventory";
import useTranslation from "../hooks/useTranslation";
import Meta from "../components/meta";
import PaginationComponent from "../components/pagination";
import useResponsive from "../hooks/useResponsive";
import { ConfigEshopPreferencesHomepage, Inventory, Pagination, Theme } from "../__generated__/graphql";
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const Catalogue = ({ isHomepage }: { isHomepage: boolean }) => {
  const location = useLocation();
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = Store.useState(s => s.theme) as Theme;
  const settings = theme.settings;
  const columns = settings?.collectionColumns?.desktop || 6;
  const { isMobile } = useResponsive({ maxWidth: settings?.mobileBreakpoint || 920 });
  const currentPath = location.pathname + location.search;
  const currentUri = new URI(currentPath);
  const isWantlist = currentPath.includes("/wantlist");
  const isProduct = currentPath.includes("/products");
  const isPreorder = currentPath.includes("/preorder");
  const [page, setPage] = useState<Inventory | undefined>(undefined);

  const searchQuery = new URI(location.pathname + location.search.replace(/\/$/, "")).normalize().search(true);
  const [showFilters, setShowFilters] = useState(searchQuery.filters === "open");

  const filters: {
    styles: string[];
    artists: number[];
    genres: string[];
    labels: number[];
    years: number[];
    formats: string[];
    mediums: string[];
    countries: string[];
    manufacturers: string[];
    types: string[];
    categories: string[];
    stock: string;
    from: string;
    page: number;
    preorders: string;
    sort?: string;
    condition?: string;
    order?: number;
    // maxPrice: number | null;
  } = {
    styles: [],
    artists: [],
    genres: [],
    labels: [],
    years: [],
    formats: [],
    mediums: [],
    countries: [],
    manufacturers: [],
    types: [],
    // maxPrice: null,
    categories: [],
    stock: isProduct ? "all" : "instock",
    from: "",
    page: 1,
    preorders: isPreorder ? "only" : "false"
  };
  if (searchQuery.styles) {
    filters.styles = Array.isArray(searchQuery.styles) ? searchQuery.styles : [searchQuery.styles];
  }
  filters.sort = searchQuery.sort || "added";

  const order = parseInt(searchQuery.order);
  filters.order = !isNaN(order) ? order : -1;

  if (searchQuery.genres) {
    filters.genres = Array.isArray(searchQuery.genres) ? searchQuery.genres : [searchQuery.genres];
  }
  if (searchQuery.labels) {
    filters.labels = (Array.isArray(searchQuery.labels) ? searchQuery.labels : [searchQuery.labels]).map(id => parseInt(id));
  }
  if (searchQuery.years) {
    filters.years = (Array.isArray(searchQuery.years) ? searchQuery.years : [searchQuery.years]).map(id => parseInt(id));
  }
  if (searchQuery.artists) {
    filters.artists = (Array.isArray(searchQuery.artists) ? searchQuery.artists : [searchQuery.artists]).map(id => parseInt(id));
  }
  if (searchQuery.formats) {
    filters.formats = Array.isArray(searchQuery.formats) ? searchQuery.formats : [searchQuery.formats];
  }
  if (searchQuery.mediums) {
    filters.mediums = Array.isArray(searchQuery.mediums) ? searchQuery.mediums : [searchQuery.mediums];
  }
  if (searchQuery.manufacturers)
    filters.manufacturers = Array.isArray(searchQuery.manufacturers) ? searchQuery.manufacturers : [searchQuery.manufacturers];
  if (searchQuery.types) filters.types = Array.isArray(searchQuery.types) ? searchQuery.types : [searchQuery.types];
  if (searchQuery.from) filters.from = searchQuery.from;
  if (searchQuery.countries) filters.countries = searchQuery.countries;
  if (searchQuery.categories) filters.categories = searchQuery.categories;
  if (searchQuery.page) filters.page = parseInt(searchQuery.page) || 1;
  if (searchQuery.stock) filters.stock = searchQuery.stock;
  if (searchQuery.condition) filters.condition = searchQuery.condition;
  // if (searchQuery.maxPrice) filters.maxPrice = searchQuery.maxPrice;
  if (searchQuery.preorders) filters.preorders = searchQuery.preorders;

  const query = {
    ...filters,
    type: !isProduct ? "all" : "product",
    limit: columns * 8,
    // maxPrice: parseInt(filters.maxPrice),
    applyDataFilters: true,
    wantlist: isWantlist
  };

  const { data, loading: inventoryLoading } = useInventory({ ...query });

  useEffect(() => {
    Store.update(s => {
      s.globalClass = "catalogue";
    });
    if (data && data.inventory) setPage(data.inventory);
  }, [data]);

  // const handlePerPageChange = (amount: number) => {
  //   Cookies.set("catalogue.limit", String(amount));
  //   currentUri.setSearch("limit", amount);
  //   navigate(currentUri.toString());
  // };

  const hasAnyFilters = () => !!location.search;

  const catalogueName = isProduct ? t("products") : isWantlist ? "Wantlist" : isPreorder ? t("preOrder") : t("catalogue");

  return (
    <div
      id="catalogue"
      className={`${isMobile ? "mobile" : ""} ${isHomepage ? "homepage" : ""} ${isProduct ? "products" : ""} ${
        isWantlist ? "wantlist" : ""
      }`}
    >
      <Meta description="Catalogue" title={isHomepage ? null : "Catalogue"} path="/catalogue" noIndex={true} />
      <h1>{catalogueName}</h1>
      {page && page.pagination ? (
        <Header
          inventoryLoading={inventoryLoading}
          isMobile={isMobile}
          pagination={page.pagination}
          currentUri={currentUri}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          initialFilters={filters}
          isShowingFilters={showFilters}
        />
      ) : null}
      <div className={`content ${showFilters ? "showFilters" : ""}`}>
        {showFilters || isMobile ? (
          <FilterSection
            isProduct={isProduct}
            isPreorder={isPreorder}
            setShowFilters={setShowFilters}
            showFilters={showFilters}
            isMobile={isMobile}
            hasAnyFilters={hasAnyFilters}
            filters={filters}
            currentUri={currentUri}
            page={page as Inventory}
          />
        ) : null}
        {page && page.items && !inventoryLoading ? (
          <div className="entries">
            {page.items && page.items.length ? page.items.map(e => <Tile key={e._id} item={e} />) : <p>{t("No results found")}</p>}
          </div>
        ) : null}
      </div>
      {page && page.pagination && !inventoryLoading ? <PaginationComponent pagination={page.pagination} currentUri={currentUri} /> : null}
    </div>
  );
};

const FilterSection = ({
  isMobile,
  hasAnyFilters,
  isPreorder,
  setShowFilters,
  currentUri,
  showFilters,
  page,
  filters,
  isProduct
}: {
  isMobile: boolean;
  hasAnyFilters: () => boolean;
  isPreorder: boolean;
  setShowFilters: (state: boolean) => void;
  currentUri: URI;
  showFilters: boolean;
  page: Inventory;
  filters: any;
  isProduct: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleFilterCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasFilter = currentUri.hasQuery(e.target.name, e.target.value, true);
    currentUri.removeQuery("page");
    if (hasFilter) currentUri.removeQuery({ [e.target.name]: e.target.value });
    else currentUri.addSearch(e.target.name, e.target.value);
    navigate(currentUri.toString());
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "showSoldOut") {
      currentUri.setSearch({ stock: e.target.checked ? "all" : "instock" });
    } else if (e.target.name === "showSecondHand")
      currentUri.setSearch({
        condition: e.target.checked ? "secondhand" : undefined
      });
    else if (e.target.name === "preorders")
      currentUri.setSearch({
        preorders: e.target.checked ? "true" : undefined
      });

    currentUri.removeQuery("page");
    navigate(currentUri.toString());
  };

  const filtersToShow = isProduct
    ? [
        {
          type: "categories",
          name: t("categories"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "manufacturers",
          name: "Manufacturers",
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "types",
          name: "Product Types",
          id: "_id",
          showId: "_id",
          layout: "column"
        }
      ]
    : [
        {
          type: "categories",
          name: t("categories"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "genres",
          name: t("genres"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "styles",
          name: t("styles"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "mediums",
          name: t("formats"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "formats",
          name: t("formatDescription"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "labels",
          name: t("labels"),
          id: "id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "artists",
          name: t("artists"),
          id: "id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "years",
          name: t("years"),
          id: "_id",
          showId: "_id",
          layout: "column"
        },
        {
          type: "countries",
          name: t("countries"),
          id: "_id",
          showId: "_id",
          layout: "column"
        }
      ];

  if (isMobile)
    return (
      <div id="filters" className={`mobile ${showFilters ? "open" : "closed"}`}>
        {page && page.filters ? (
          <>
            <div className="header" onClick={() => setShowFilters(false)}>
              <button className="primary" onClick={() => setShowFilters(false)}>
                {showFilters ? t("hideFilters") : t("showFilters")}
              </button>
            </div>
            <hr />
            {hasAnyFilters() && page ? <ClearFilter currentUri={currentUri} /> : null}
            <div className="filterSection column showSoldOut">
              {!isPreorder ? (
                <>
                  <div className="filter">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="showSecondHand"
                        onChange={e => handleCheckboxChange(e)}
                        checked={filters.condition === "secondhand"}
                      />
                      {t("showSecondHand")}
                    </label>
                  </div>
                  <div className="filter">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="preorders"
                        onChange={e => handleCheckboxChange(e)}
                        checked={filters.preorders === "true"}
                      />
                      {t("showPreorders")}
                    </label>
                  </div>
                  <div className="filter">
                    <label className="checkbox">
                      <input type="checkbox" name="showSoldOut" onChange={e => handleCheckboxChange(e)} checked={filters.stock === "all"} />
                      {t("showSoldOut")}
                    </label>
                  </div>{" "}
                </>
              ) : null}
            </div>
            {filtersToShow
              .filter(f => page.filters[f.type].length)
              .map((f, i) => (
                <Filters
                  key={i}
                  isTabletOrMobile={isMobile}
                  initialFilter={filters[f.type]}
                  currentUri={currentUri}
                  filter={f}
                  onChange={e => handleFilterCheckboxChange(e)}
                  entries={page.filters[f.type]}
                />
              ))}
          </>
        ) : null}
      </div>
    );
  else
    return (
      <div id="filters" className={`${isMobile ? "mobile" : ""}`}>
        {page && page.filters ? (
          <>
            {hasAnyFilters() && page ? <ClearFilter currentUri={currentUri} /> : null}
            <div className="filterSection column showSoldOut">
              {!isPreorder ? (
                <>
                  <div className="filter">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="showSecondHand"
                        onChange={e => handleCheckboxChange(e)}
                        checked={filters.condition === "secondhand"}
                      />
                      {t("showSecondHand")}
                    </label>
                  </div>
                  <div className="filter">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="preorders"
                        onChange={e => handleCheckboxChange(e)}
                        checked={filters.preorders === "true"}
                      />
                      {t("showPreorders")}
                    </label>
                  </div>
                  <div className="filter">
                    <label className="checkbox">
                      <input type="checkbox" name="showSoldOut" onChange={e => handleCheckboxChange(e)} checked={filters.stock === "all"} />
                      {t("showSoldOut")}
                    </label>
                  </div>
                </>
              ) : null}
            </div>
            {filtersToShow
              .filter(f => page.filters[f.type].length)
              .map((f, i) => (
                <Filters
                  key={i}
                  onChange={e => handleFilterCheckboxChange(e)}
                  isTabletOrMobile={isMobile}
                  initialFilter={filters[f.type]}
                  currentUri={currentUri}
                  filter={f}
                  entries={page.filters[f.type]}
                />
              ))}
          </>
        ) : null}
      </div>
    );
};

const Filters = ({
  entries,
  filter,
  initialFilter,
  currentUri,
  isTabletOrMobile,
  onChange
}: {
  entries: any;
  filter: any;
  initialFilter: any;
  currentUri: URI;
  isTabletOrMobile: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const limit = isTabletOrMobile ? 0 : 8;
  return (
    <div className={`filterSection ${filter.layout} ${filter.type}`}>
      <div className="header">
        <h3 className="name" onClick={() => setIsCollapsed(!isCollapsed)}>
          {filter.name}
        </h3>
        {entries.length > limit ? (
          <button onClick={() => setIsCollapsed(!isCollapsed)} type="button">
            {!isCollapsed ? (
              <span>
                {t("more")} <i className="cg-icon-filter-more" />
              </span>
            ) : (
              <span>
                {t("less")} <i className="cg-icon-filter-less" />
              </span>
            )}
          </button>
        ) : null}
      </div>
      <div className="filterEntries">
        {entries
          .filter((_: any, i: number) => (!isCollapsed ? i < limit : 1))
          .map((g: any) => (
            <div key={g._id} className={`filter ${initialFilter.includes(g[filter.id]) ? "selected" : ""}`}>
              {filter.layout === "row" ? (
                <Link
                  to={
                    initialFilter.includes(g[filter.id])
                      ? currentUri
                          .clone()
                          .removeSearch({
                            [filter.type]: [g[filter.id]]
                          })
                          .removeSearch("page")
                          .toString()
                      : currentUri
                          .clone()
                          .addSearch({
                            [filter.type]: g[filter.id]
                          })
                          .removeSearch("page")
                          .toString()
                  }
                >
                  {g[filter.showId]}
                </Link>
              ) : null}
              {filter.layout === "column" ? (
                <label className="checkbox">
                  <input
                    type="checkbox"
                    onChange={onChange}
                    name={filter.type}
                    value={g[filter.id]}
                    checked={initialFilter.includes(g[filter.id])}
                  />
                  {g[filter.showId]}
                </label>
              ) : null}
            </div>
          ))}
      </div>
    </div>
  );
};

const ClearFilter = ({ currentUri }: { currentUri: URI }) => {
  const { t } = useTranslation();

  return (
    <div className="clearFilters filterSection row">
      <Link to={new URI(currentUri).search("").toString()}>
        <button className="">
          <span>{t("clearAllFilters")}</span>
          <i className="cg-icon-close" />
        </button>
      </Link>
      <hr />
    </div>
  );
};

const Header = ({
  inventoryLoading,
  pagination,
  currentUri,
  isMobile,
  initialFilters,
  showFilters,
  setShowFilters,
  isShowingFilters
}: {
  inventoryLoading: boolean;
  pagination: Pagination;
  currentUri: URI;
  isMobile: boolean;
  initialFilters: any;
  showFilters: boolean;
  setShowFilters: (state: boolean) => void;
  isShowingFilters: boolean;
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const sorts = [
    {
      active: initialFilters.order === -1 && initialFilters.sort === "price",
      query: { sort: "price", order: -1 },
      name: t("priceDesc")
    },
    {
      active: initialFilters.order === 1 && initialFilters.sort === "price",
      query: { sort: "price", order: 1 },
      name: t("priceAsc")
    },
    {
      active: initialFilters.order === -1 && initialFilters.sort === "added",
      query: { sort: "added", order: -1 },
      name: t("addedDesc")
    },
    {
      active: initialFilters.order === 1 && initialFilters.sort === "added",
      query: { sort: "added", order: 1 },
      name: t("addedAsc")
    }
  ];

  const sortIndex = sorts.findIndex(s => s.active) || 0;

  const handleSortUpdate = () => {
    const newIndex = (sortIndex + 1) % 4;
    const query = sorts[newIndex].query;
    navigate(
      currentUri
        .clone()
        .setSearch({ ...currentUri.search(true), ...query })
        .toString()
    );
  };

  const search = currentUri.search(true);
  const filterCount = Object.keys(search)
    .filter(k => k !== "stock")
    .reduce((acc, e) => {
      const value = search[e];
      if (Array.isArray(value)) return acc + value.length;
      else return acc + 1;
    }, 0);

  return (
    <div className="header">
      <div className="left">
        <button className="primary" onClick={() => setShowFilters(!showFilters)}>
          {isShowingFilters ? t("hideFilters") : t("showFilters")} {filterCount ? `(${filterCount})` : null}
        </button>
        {!isMobile ? (
          <button type="button" className="primary" onClick={handleSortUpdate}>
            {sorts[sortIndex].name}
          </button>
        ) : null}
        {inventoryLoading ? <Loader /> : null}
      </div>
      <div className="right">
        <PaginationComponent pagination={pagination} currentUri={currentUri} />
      </div>
    </div>
  );
};

const Routes = (homepage?: ConfigEshopPreferencesHomepage | null) => {
  const routes = [
    {
      path: "/catalogue",
      element: <Catalogue isHomepage={false} />
    },
    {
      path: "/products",
      element: <Catalogue isHomepage={false} />
    },
    {
      path: "/preorder",
      element: <Catalogue isHomepage={false} />
    },
    {
      path: "/wantlist",
      element: <Catalogue isHomepage={false} />
    }
  ];

  if (homepage?.type === "catalogue") routes.push({ path: "/", element: <Catalogue isHomepage={true} /> });

  return routes;
};

export default Catalogue;

export { Routes };
