import { Store as PullStateSTore } from "pullstate";
import { Checkout, Config, Item, Session, Theme, Track } from "./__generated__/graphql";

export interface RegisterMethod {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  withVerificationCode: boolean;
}

export interface VerifyMethod {
  code: number;
  checkoutId: string;
}

interface StoreTypes {
  config?: Config;
  theme?: Theme;
  session?: Session | null;
  player: any;
  globalClass: string;
  cookies: any;
  isMobile: boolean;
  reloadConfig: any;
  checkout?: Checkout;
  login?: any;
  updateAndSetCheckout?: any;
  setHomeOpen?: (status: boolean) => void;
  updateBasket?: (listingId: number) => void;
  checkoutUpdateQuantity?: (listingId: number, quantity: number) => void;
  updateCheckout?: (checkout: Checkout) => void;
  addVoucher?: any;
  removeVoucher?: any;
  createNewCheckout?: any;
  logout?: () => void;
  register?: (arg0: RegisterMethod) => void;
  verify?: (arg0: VerifyMethod) => void;
  resetStore?: () => void;
}

export const Store = new PullStateSTore<StoreTypes>({
  globalClass: "",
  session: undefined,
  reloadConfig: undefined,
  config: undefined,
  player: {},
  cookies: {},
  isMobile: false,
  theme: undefined
});

// export interface PlayerTrack {
//   uri?: string | null;
//   title?: string | null;
//   position: string;
// }
export interface PlayerTrackToPlay {
  release: Item;
  track: Track;
}
export interface TracklistToLoad {
  playOffset: number;
  tracklist: { track: Track; release: Item }[];
}

export interface TracklistTrack {
  track: Track;
  release: Item;
}

interface PlayerStoreTypes {
  tracklist: TracklistTrack[];
  shouldPlayNext: boolean;
  isShowing: boolean;
  isLoading: boolean;
  shouldPlayPrevious: boolean;
  position: number;
  isPlaying: boolean;
  currentTrackData: PlayerTrackToPlay | null;
  stopAndClose?: () => void;
  loadTracklist?: (tracklistToLoad: TracklistToLoad) => void;
  playPauseTrack?: () => void;
}

export const PlayerStore = new PullStateSTore<PlayerStoreTypes>({
  tracklist: [],
  isPlaying: false,
  position: 0,
  shouldPlayNext: false,
  shouldPlayPrevious: false,
  isShowing: false,
  isLoading: false,
  currentTrackData: null
});
