import { gql } from "../../__generated__/gql";

export const ARTICLE_FIELDS = gql(`
  fragment ArticleFields on Article {
    _id
    id
    homepage
    title
    tags
    handle
    seo {
      index
      description
    }
    published {
      date
    }
    content {
      rows {
        id
        layout
        options {
          fullWidth
          carousel
          backgroundColor
          noPadding
        }
        columns {
          id
          blocks {
          type
          id
          data
          }
        }
      }
    }
    thumbnail {
      title
      url
      alt
      formatArray {
        _id
        url
        name
      }
    }
  }
`);

export const GET_ARTICLE = gql(`
  query article($homepage: Boolean, $id: Int, $handle: String $preview: Boolean) {
    article(homepage: $homepage, id: $id, handle: $handle preview: $preview) {
      ...ArticleFields
    }
  }
`);

export const GET_ARTICLES = gql(`
  query articles($tags: [String!] $sortBy: String, $sortOrder: Int) {
    articles(tags: $tags, sortBy: $sortBy, sortOrder: $sortOrder) {
      ...ArticleFields
    }
 }
`);
