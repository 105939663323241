import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "../components/carousel";
import { usePlaylist, useCollection } from "../hooks/useInventory";
import { useMutation, useQuery } from "@apollo/client";
import useInView from "../hooks/useInView";
import { Tile } from "../components/views";
import Parse, { DOMNode, domToReact, Element } from "html-react-parser";
import URI from "urijs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Store } from "../store";
import useNotification, { AddNotification } from "../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { findUrlForMediaArray } from "../utils";
import { GET_ARTICLE, GET_ARTICLES } from "../graphql/queries/article";
import { GET_ITEMS } from "../graphql/queries/inventory";
import { POST_LIST_OPTIN } from "../graphql/queries/user";
import { Article, ArticleContentBlock } from "../__generated__/graphql";

export const replaceLink = (domNode: DOMNode) => {
  if (domNode instanceof Element && domNode.name === "a" && domNode.attribs.href && !new URI(domNode.attribs.href).is("absolute")) {
    return <Link to={domNode.attribs.href}>{domToReact(domNode.children as DOMNode[])}</Link>;
  } else domToReact([domNode]);
};

const Block = ({ block }: { block: ArticleContentBlock }) => {
  const { isMobile } = Store.useState(s => s);
  const { addNotification } = useNotification();

  const classes = "block ";

  if (!block) return null;

  switch (block.type) {
    case "text": {
      return <div className={classes + "text paragraph"}>{Parse(block.data.content || "", { replace: replaceLink })}</div>;
    }
    case "embed": {
      const replace = (domNode: DOMNode) => {
        if (domNode instanceof Element && domNode.name === "a" && domNode.attribs.href && !new URI(domNode.attribs.href).is("absolute")) {
          return <Link to={domNode.attribs.href}>{domToReact(domNode.children as DOMNode[])}</Link>;
        } else domToReact([domNode]);
      };
      return <div className={classes + "embed"}>{Parse(block.data.html || "", { replace })}</div>;
    }
    case "separator":
      return (
        <div className={classes + "separator"}>
          <hr />
        </div>
      );
    case "carousel":
      return (
        <div className={classes + "images carousel"}>
          <Carousel block={block} />
        </div>
      );
    case "playlist":
      return <PlaylistBlock block={block} isMobile={isMobile} />;
    case "subscribe": {
      if (!block.data.listRef) return null;
      return <SubscribeBlock listRef={block.data.listRef} isMobile={isMobile} addNotification={addNotification} />;
    }
    case "collection":
      return <CollectionBlock block={block} isMobile={isMobile} />;
    case "article":
      return <ArticleBlock block={block} />;
    case "items":
      return <ItemsBlock block={block} isMobile={isMobile} />;
    case "blog":
      return <BlogBlock block={block} isMobile={isMobile} />;
    case "image": {
      if (!block.data || !block.data.media) break;
      const link = block.data.to || block.data.link;
      const imageUrl = findUrlForMediaArray(block.data.media, block.data.format);
      const propsToPass = { alt: block.data?.media?.alt, title: block.data.media?.caption };
      if (link && imageUrl) {
        if (link.includes("http"))
          return (
            <a target="_blank" rel="noreferrer noopener" href={link} className={classes + "image"}>
              <LazyLoadImage {...propsToPass} src={imageUrl} />
            </a>
          );
        else
          return (
            <Link to={link} className="image">
              <LazyLoadImage {...propsToPass} src={imageUrl} />
            </Link>
          );
      } else if (imageUrl)
        return (
          <div className={"image"}>
            <LazyLoadImage {...propsToPass} src={imageUrl} />
          </div>
        );
      else return null;
    }
    default: {
      return null;
    }
  }

  return null;
};

const ArticleBlock = ({ block }: { block: ArticleContentBlock }) => {
  const { data, loading } = useQuery(GET_ARTICLE, { variables: { id: block.data.articleId } });

  const article = data?.article;
  if (loading || !article) return null;

  const format = block.data.format;
  const image = article.thumbnail ? findUrlForMediaArray(article.thumbnail, format) : null;

  return (
    <div className="block article">
      {image ? (
        <Link to={article.handle}>
          <LazyLoadImage src={image} alt={article.thumbnail?.alt || ""} title={article.title} />
        </Link>
      ) : null}
      <Link to={article.handle}>
        <h2>{article.title}</h2>
      </Link>
    </div>
  );
};

const ItemsBlock = ({ block, isMobile }: { block: ArticleContentBlock; isMobile: boolean }) => {
  const { data, loading } = useQuery(GET_ITEMS, { variables: { ids: block.data.entries } });

  const items = data?.items;
  if (loading || !items) return null;

  return (
    <div className="block items">
      {block.data.title ? <h2 className="title">{block.data.title}</h2> : null}
      <div className="entries" style={{ gridTemplateColumns: `repeat(${isMobile ? 2 : block.data.entriesPerRow}, 1fr)` }}>
        {items.map((e, idx) => (
          <Tile key={idx} item={e} />
        ))}
      </div>
    </div>
  );
};

const PlaylistBlock = ({ block, isMobile }: { block: ArticleContentBlock; isMobile: boolean }) => {
  const [ref, onScreen] = useInView({ triggerOnce: true });
  const { getPlaylist, playlist } = usePlaylist({ id: block.data.id });

  useEffect(() => {
    if (onScreen) getPlaylist();
  }, [onScreen, getPlaylist]);

  return (
    <div ref={ref} className={`block playlist ${isMobile ? "mobile" : ""}`}>
      {playlist ? (
        <div className="content">
          <div className="header">
            {playlist?.link ? (
              <Link to={playlist.link}>
                <h2 className="title">{playlist.title}</h2>
              </Link>
            ) : (
              <h2 className="title">{playlist?.title}</h2>
            )}
          </div>
          <div className="entries">
            {playlist?.entries?.map((e, i) => (
              <div key={i} className="playlistEntry">
                <Tile item={e.item} />
                <p>{e.comments}</p>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const CollectionBlock = ({ block, isMobile }: { block: ArticleContentBlock; isMobile: boolean }) => {
  const { t } = useTranslation();
  const [ref, onScreen] = useInView({ triggerOnce: true });
  const variables = {
    id: block.data.id,
    randomise: !!block.data.randomise,
    limit: block.data.limit,
    sort: block.data.sort,
    order: block.data.order
  };

  const { getCollection, collection } = useCollection(variables);

  useEffect(() => {
    if (onScreen) {
      getCollection();
    }
  }, [onScreen, getCollection]);

  const link = `${collection?.collection?.handle}?sort=${block.data.sort}&order=${block.data.order}`;

  return (
    <div ref={ref} className={`block collection ${isMobile ? "mobile" : ""}`}>
      <div className="content">
        <div className="header">
          <Link className="title" to={link}>
            <h2>{collection?.collection?.title}</h2>
          </Link>
          <Link className="seeMore" to={link}>
            <button className="primary" type="button">
              {t("seeMore")}
            </button>
          </Link>
        </div>
        <div className="entries">
          {collection?.page?.items?.map(e => (
            <Tile key={e.id} item={e} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SubscribeBlock = ({
  addNotification,
  listRef,
  isMobile
}: {
  addNotification: AddNotification;
  listRef: string;
  isMobile: boolean;
}) => {
  const [subscribeMessage, setSubscribeMessage] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const [optin, { loading }] = useMutation(POST_LIST_OPTIN);

  const handleAddToNewsletter = async (e: React.SyntheticEvent) => {
    const target = e.target as typeof e.target & { email: { value: string } };
    const email = target.email.value;
    e.preventDefault();
    try {
      const { data } = await optin({ variables: { email, listRef } });
      if (data?.listOptin) setSubscribeMessage(data.listOptin);
    } catch (e: any) {
      addNotification({ ok: 0, message: e.toString() });
    }
  };

  return (
    <div className={`block subscribe ${isMobile ? "mobile" : ""}`}>
      <h2>{t("subscribe")}</h2>
      <form onSubmit={handleAddToNewsletter}>
        {!subscribeMessage ? (
          <div className="content">
            <input type="email" name="email" placeholder={t("enterEmail") as string} required />
            <button type="submit" className="primary" disabled={!!subscribeMessage || !!loading}>
              {t("subscribe")}
            </button>
          </div>
        ) : (
          <p>{subscribeMessage}</p>
        )}
      </form>
    </div>
  );
};

const BlogBlock = ({ isMobile, block }: { isMobile: boolean; block: ArticleContentBlock }) => {
  const { data } = useQuery(GET_ARTICLES, {
    variables: { tags: block.data.tags, sortBy: block.data.sort?.key || "published", sortOrder: block.data.sort?.order || -1 }
  });

  const BlogEntry = ({ article, format }: { article: Article; format: string }) => {
    const image = article.thumbnail ? findUrlForMediaArray(article.thumbnail, format) : null;

    return (
      <div key={article._id} className="entry">
        {image ? (
          <Link to={article.handle}>
            <LazyLoadImage alt={article.thumbnail?.alt || ""} src={image} />
          </Link>
        ) : null}
        <Link to={article.handle}>
          <h2>{article.title}</h2>
        </Link>
      </div>
    );
  };

  const articles = data?.articles || [];
  return (
    <div className={`block blog ${isMobile ? "mobile" : ""}`}>
      <div className={`entries columns-${block.data.columns}`}>
        {articles.map(a => (
          <BlogEntry key={a._id} article={a} format={block.data.format} />
        ))}
      </div>
    </div>
  );
};

export default Block;
export { SubscribeBlock };
