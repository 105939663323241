import React from "react";
import Price from "./price";
import { Link } from "react-router-dom";
import { PlayerStore, Store } from "../store";
import useWantlist from "../hooks/useWantlist";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import useResponsive from "../hooks/useResponsive";
import { PlayerButtonDetailed } from "../routes/item";
import Image from "./image";
import { Config, Item, ItemArtist, Listing } from "../__generated__/graphql";
import { TFunction } from "i18next";

const Tile = ({ item }: { item: Item }) => {
  const config = Store.useState(s => s.config) as Config;
  const forSaleListings = item.listings ? item.listings.filter(l => l.stock.quantity > 0) : [];
  const cheapest = forSaleListings.length ? forSaleListings[0] : undefined;
  const wantlistEnabled = config.eshop.preferences?.wantlist?.enabled === true;
  const playerEnabled = config.eshop.preferences?.player?.enabled;

  const ReleaseTile = ({
    item,
    config,
    cheapest,
    wantlistEnabled
  }: {
    item: Item;
    config: Config;
    cheapest?: Listing;
    wantlistEnabled?: boolean;
  }) => {
    const { addToWantlist } = useWantlist();
    const path = item.path;
    const { loadTracklist, currentTrackData, isPlaying, playPauseTrack } = PlayerStore.useState(s => s);

    const buttonIsPlaying = isPlaying && currentTrackData && currentTrackData.release._id === item._id;
    const active = config.eshop.preferences?.player?.enabled;

    const tracklistToLoad = {
      tracklist: item.data.tracklist?.filter(t => t.uri).map(t => ({ track: t, release: item })) || [],
      playOffset: 0
    };

    return (
      <div className={`tile releaseItem ${getViewClasses(item)}`}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main || ""} image={item.data?.images[0]} />
        </Link>
        <div className="priceAndActions">
          <PriceAndSecondHandSection listing={cheapest} />
          <div className="actions">
            {playerEnabled && active && item.data.tracklist?.filter(t => t.uri).length ? (
              <button type="button" onClick={() => (buttonIsPlaying ? (playPauseTrack as any)() : (loadTracklist as any)(tracklistToLoad))}>
                <i className={`cg-icon-${buttonIsPlaying ? "pause" : "play"}`} />
              </button>
            ) : null}
            {wantlistEnabled ? (
              <button type="button" onClick={() => addToWantlist(item)}>
                <i className={item.wanted ? "cg-icon-wantlist-fill" : "cg-icon-wantlist"} />
              </button>
            ) : null}
          </div>
        </div>
        <ArtistNames artists={item.data.artists || []} />
        <p className="title">
          <Link to={path}>{item.data.title}</Link>
        </p>
      </div>
    );
  };

  const ProductTile = ({ item, cheapest, wantlistEnabled }: { item: Item; cheapest?: Listing; wantlistEnabled: boolean }) => {
    const { addToWantlist } = useWantlist();

    const path = item.path;

    return (
      <div className="tile productItem">
        <Link to={path}>
          <Image alt={item.descriptions.main || ""} image={item.data?.images[0]} />
        </Link>
        <div className="priceAndActions">
          <PriceAndSecondHandSection listing={cheapest} />
          <div className="actions">
            {wantlistEnabled ? (
              <button type="button" onClick={() => addToWantlist(item)}>
                <i className={item.wanted ? "cg-icon-wantlist-fill" : "cg-icon-wantlist"} />
              </button>
            ) : null}
          </div>
        </div>
        <p className="title">
          <Link to={path}>{item.data.title}</Link>
        </p>
      </div>
    );
  };

  const BookTile = ({ item, cheapest, wantlistEnabled }: { item: Item; cheapest?: Listing; wantlistEnabled: boolean }) => {
    const { addToWantlist } = useWantlist();

    const path = item.path;

    return (
      <div className="tile productItem">
        <Link to={path}>
          <Image alt={item.descriptions.main || ""} image={item.data?.images[0]} />
        </Link>
        <div className="priceAndActions">
          <PriceAndSecondHandSection listing={cheapest} />
          <div className="actions">
            {wantlistEnabled ? (
              <button type="button" onClick={() => addToWantlist(item)}>
                <i className={item.wanted ? "cg-icon-wantlist-fill" : "cg-icon-wantlist"} />
              </button>
            ) : null}
          </div>
        </div>
        <p className="authors">
          {item.data.authors?.map(a => (
            <span className="author" key={a}>
              {a}
            </span>
          ))}
        </p>
        <p className="title">
          <Link to={path}>{item.data.title}</Link>
        </p>
      </div>
    );
  };

  if (item.type === "ReleaseItem") return <ReleaseTile item={item} config={config} cheapest={cheapest} wantlistEnabled={wantlistEnabled} />;
  else if (item.type === "ProductItem") return <ProductTile item={item} cheapest={cheapest} wantlistEnabled={wantlistEnabled} />;
  else if (item.type === "BookItem") return <BookTile item={item} cheapest={cheapest} wantlistEnabled={wantlistEnabled} />;
  else return null;
};

const LabelNames = ({ item }: { item: Item }) => {
  if (item.data.labels)
    return (
      <>
        {item.data.labels.map((l, index) => (
          <span key={index + l.name}>
            {l.id ? <Link to={`/catalogue?labels=${l.id}`}>{l.name} </Link> : `${l.name}`} ({l.catno}) <br />
          </span>
        ))}
      </>
    );
  else return null;
};

const List = ({ item }: { item: Item }) => {
  const config = Store.useState(s => s.config) as Config;
  const forSaleListings = item.listings ? item.listings.filter(l => l.stock.quantity > 0) : [];
  const cheapest = forSaleListings.length ? forSaleListings[0] : undefined;
  const wantlistEnabled = config.eshop.preferences?.wantlist?.enabled;
  const playerEnabled = config.eshop.preferences?.player?.enabled;
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const AddToWantlist = ({ item }: { item: Item }) => {
    const { addToWantlist } = useWantlist();

    return (
      <button type="button" onClick={() => addToWantlist(item)}>
        <i className={item.wanted ? "cg-icon-wantlist-fill" : "cg-icon-wantlist"} />
      </button>
    );
  };

  const path = item.path;
  const ReleaseList = ({ item }: { item: Item }) => {
    const tracklistToLoad = {
      tracklist: item.data.tracklist?.filter(t => t.uri).map(t => ({ track: t, release: item })) || [],
      playOffset: 0
    };
    const { loadTracklist, currentTrackData, isPlaying, playPauseTrack } = PlayerStore.useState(s => s);
    const buttonIsPlaying = isPlaying && currentTrackData && currentTrackData.release._id === item._id;
    const active = config.eshop.preferences?.player?.enabled;

    return (
      <div className={`list releaseItem ${getViewClasses(item)}` + (isMobile ? " mobile" : "")}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main || ""} image={item.data?.images[0]} />
        </Link>
        <div className="information">
          <ArtistNames artists={item.data.artists || []} />
          <p className="title">
            <Link to={path}>{item.data.title}</Link>
          </p>
          <Specs item={item} t={t} />
        </div>
        <div className="priceAndActions">
          <PriceAndSecondHandSection listing={cheapest} />
          <div className="actions">
            {playerEnabled && active && item.data.tracklist?.filter(t => t.uri).length ? (
              <button type="button" onClick={() => (buttonIsPlaying ? (playPauseTrack as any)() : (loadTracklist as any)(tracklistToLoad))}>
                <i className={`cg-icon-${buttonIsPlaying ? "pause" : "play"}`} />
              </button>
            ) : null}
            {wantlistEnabled ? <AddToWantlist item={item} /> : null}
          </div>
        </div>
      </div>
    );
  };

  const ProductList = ({ item }: { item: Item }) => {
    return (
      <div className={"list prouctItem" + (isMobile ? " mobile" : "")}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main} image={item.data?.images[0]} />
        </Link>
        <div className="information">
          <p className="title">
            <Link to={path}>{item.data.title}</Link>
          </p>
          <Specs item={item} t={t} />
        </div>
        <div className="priceAndActions">
          <PriceAndSecondHandSection listing={cheapest} />
          <div className="actions">{wantlistEnabled ? <AddToWantlist item={item} /> : null}</div>
        </div>
      </div>
    );
  };

  const BookList = ({ item }: { item: Item }) => {
    return (
      <div className={"list bookItem" + (isMobile ? " mobile" : "")}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main} image={item.data?.images[0]} />
        </Link>
        <div className="information">
          <p className="title">
            <Link to={path}>
              {item.data.title} {item.data.subtitle}
            </Link>
          </p>
          <Specs item={item} t={t} />
        </div>
        <div className="priceAndActions">
          <PriceAndSecondHandSection listing={cheapest} />
          <div className="actions">{wantlistEnabled ? <AddToWantlist item={item} /> : null}</div>
        </div>
      </div>
    );
  };

  const Specs = ({ item, t }: { item: Item; t: TFunction }) => {
    if (item.type === "ReleaseItem")
      return (
        <div className="specs">
          <p className="labels">
            <LabelNames item={item} />
          </p>
          {item.data.releaseDate ? (
            <p>{item.data.country ? <Link to={`/catalogue?countries=${item.data.country}`}>{item.data.country}</Link> : null}</p>
          ) : null}
          <div className="styles">
            {item.data.styles?.map(s => (
              <Link key={s} to={`/catalogue?styles=${s}`}>
                {s}{" "}
              </Link>
            ))}
          </div>
        </div>
      );
    else if (item.type === "ProductItem")
      return (
        <div className="specs">
          <p>
            {item.data.manufacturer} {item.data.cat ? `(${item.data.cat})` : ""}
          </p>
        </div>
      );
    else if (item.type === "BookItem")
      return (
        <div className="specs">
          {item.data.publisher ? <p>{item.data.publisher}</p> : null}
          <p>
            {item.data.pageCount} {t("pages")}, {item.data.language}
          </p>
          {item.data.categories?.length ? <p>{item.data.categories.join(", ")}</p> : null}

          {item.data.publishedDate ? (
            <p>
              {t("publicationDate")}: {DateTime.fromMillis(item.data.publishedDate).toFormat("DD")}
            </p>
          ) : null}
        </div>
      );
    else return null;
  };

  if (item.type === "ReleaseItem") return <ReleaseList item={item} />;
  else if (item.type === "ProductItem") return <ProductList item={item} />;
  else if (item.type === "BookItem") return <BookList item={item} />;
  else return null;
};

const Full = ({ item }: { item: Item }) => {
  const config = Store.useState(s => s.config) as Config;
  const forSaleListings = item.listings ? item.listings.filter(l => l.stock.quantity > 0) : [];
  const cheapest = forSaleListings.length ? forSaleListings[0] : undefined;
  const wantlistEnabled = config.eshop.preferences?.wantlist?.enabled;
  const { isMobile } = useResponsive();

  const AddToWantlist = ({ item }: { item: Item }) => {
    const { addToWantlist } = useWantlist();

    return (
      <button type="button" onClick={() => addToWantlist(item)}>
        <i className={item.wanted ? "cg-icon-wantlist-fill" : "cg-icon-wantlist"} />
      </button>
    );
  };

  const path = item.path;
  const ReleaseFull = ({ item }: { item: Item }) => {
    return (
      <div className={`full releaseItem ${getViewClasses(item)}` + (isMobile ? " mobile" : "")}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main} image={item.data?.images[0]} />
        </Link>
        <div className="right">
          <div className="information">
            <ArtistNames artists={item.data.artists || []} />
            <p className="title">
              <Link to={path}>{item.data.title}</Link>
            </p>
            <div className="actions">
              <PriceAndSecondHandSection listing={cheapest} />
              <div className="right">{wantlistEnabled ? <AddToWantlist item={item} /> : null}</div>
            </div>
            <Specs item={item} />
            <p className="shortDescription" dangerouslySetInnerHTML={{ __html: item.descriptions.shop?.short || "" }} />
            <div className="tracklist">
              {item.data.tracklist
                ?.filter(t => t.type_ === "track")
                .map((t, index) => (
                  <PlayerButtonDetailed key={`${t.position}-${index}`} track={t} release={item} />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ProductFull = ({ item }: { item: Item }) => {
    return (
      <div className={`full productItem ${getViewClasses(item)}` + (isMobile ? " mobile" : "")}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main} image={item.data?.images[0]} />
        </Link>
        <div className="information">
          <p className="title">
            <Link to={path}>{item.data.title}</Link>
          </p>
          <Specs item={item} />
        </div>
        <div className="actions">
          <PriceAndSecondHandSection listing={cheapest} />
          {wantlistEnabled ? <AddToWantlist item={item} /> : null}
        </div>
      </div>
    );
  };

  const BookFull = ({ item }: { item: Item }) => {
    return (
      <div className={`full bookItem ${getViewClasses(item)}` + (isMobile ? " mobile" : "")}>
        <Link to={path} className="artwork">
          <Image alt={item.descriptions.main} image={item.data?.images[0]} />
        </Link>
        <div className="information">
          <p className="title">
            <Link to={path}>
              {item.data.title} {item.data.subtitle}
            </Link>
          </p>
          <Specs item={item} />
        </div>
        <div className="actions">
          <PriceAndSecondHandSection listing={cheapest} />
          {wantlistEnabled ? <AddToWantlist item={item} /> : null}
        </div>
      </div>
    );
  };

  const Specs = ({ item }: { item: Item }) => {
    const { t } = useTranslation();

    if (item.type === "ReleaseItem")
      return (
        <div className="specs">
          <p className="labels">
            <LabelNames item={item} />
          </p>
          {item.data.releaseDate ? (
            <p>{item.data.country ? <Link to={`/catalogue?countries=${item.data.country}`}>{item.data.country}</Link> : null}</p>
          ) : null}
          <div className="styles">
            {item.data.styles?.map(s => (
              <Link key={s} to={`/catalogue?styles=${s}`}>
                {s}{" "}
              </Link>
            ))}
          </div>
        </div>
      );
    else if (item.type === "ProductItem")
      return (
        <div className="specs">
          <p>
            {item.data.manufacturer} {item.data.cat ? `(${item.data.cat})` : ""}
          </p>
        </div>
      );
    else if (item.type === "BookItem")
      return (
        <div className="specs">
          <p>
            {t("publisher")}: {item.data.publisher}
          </p>
          <p>
            {t("pages")}: {item.data.pageCount}
          </p>
          <p>
            {t("categories")}: {item.data.categories?.join(", ")}
          </p>
          <p>
            {t("language")}: {item.data.language}
          </p>
          {item.data.publishedDate ? (
            <p>
              {t("publicationDate")}: {DateTime.fromMillis(item.data.publishedDate).toFormat("DD")}
            </p>
          ) : null}
        </div>
      );
    else return null;
  };

  if (item.type === "ReleaseItem") return <ReleaseFull item={item} />;
  else if (item.type === "ProductItem") return <ProductFull item={item} />;
  else if (item.type === "BookItem") return <BookFull item={item} />;
  else return null;
};

const PriceAndSecondHandSection = ({ listing }: { listing?: Listing }) => {
  const { t } = useTranslation();
  return (
    <p className="price">
      {listing ? <Price listing={listing} /> : t("soldOut")}
      {listing?.prices.compare ? <Price className="discounted" value={listing.prices.compare} /> : null}
      {listing?.secondHand ? <span className="secondHand"></span> : null}
    </p>
  );
};

export function ArtistNames({ artists }: { artists: ItemArtist[] }) {
  return (
    <p className="artists">
      {artists.map((a, index) => (
        <span key={index + a.name}>
          {a.id ? <Link to={`/catalogue?artists=${a.id}`}>{a.anv || a.name}</Link> : `${a.anv || a.name}`}
          {index < artists.length - 1 ? (a.join ? `${a.join !== "," ? " " : ""}${a.join} ` : ", ") : ""}
        </span>
      ))}
    </p>
  );
}

const getViewClasses = (item: Item) => {
  const classes = new Set();

  item.listings.forEach(l => {
    if (l.preOrder) classes.add("preorder");
    if (l.secondHand) classes.add("secondHand");
    if (!l.secondHand) classes.add("new");
    if (l.prices.compare) classes.add("discount");
  });
  const soldout = item.listings.filter(l => l.stock.quantity > 0).length === 0;
  if (soldout) classes.add("soldOut");

  return Array.from(classes).join(" ");
};

export { Tile, List, Full };
