import React, { useState, useEffect } from "react";
import { useLoaderData, Link } from "react-router-dom";
import useNotification, { AddNotification } from "../hooks/useNotification";
import Four0Four from "./404";
import { useTranslation } from "react-i18next";
import Suggestions from "../components/suggestions";
import { FacebookShareButton, PinterestShareButton, TwitterShareButton } from "react-share";
import { Store, PlayerStore } from "../store";
import copy from "copy-to-clipboard";
import useResponsive from "../hooks/useResponsive";
import useWantlist from "../hooks/useWantlist";
import { useQuery, useMutation } from "@apollo/client";
import useCheckout from "../hooks/useCheckout";
import { DateTime } from "luxon";
import Price from "../components/price";
import Meta from "../components/meta";
import { t } from "i18next";
import Image from "../components/image";
import { getConfigProperty } from "../utils";
import { GET_ITEM, POST_RELEASE_SNIPPET_ARCHIVE } from "../graphql/queries/inventory";
import { Config, Item, Listing, Option, Session, Theme, Track } from "../__generated__/graphql";

const ItemComponent = () => {
  const { config, session } = Store.useState(s => s) as { config: Config; session?: Session };
  const theme = Store.useState(s => s.theme) as Theme;
  const { addNotification } = useNotification();
  const { isMobile } = useResponsive();
  const {
    data: { id }
  } = useLoaderData() as { data: { id: number } };

  const { data, loading } = useQuery(GET_ITEM, { variables: { id } });
  const item = data?.item;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading || item === undefined) return null;
  else if (data && item === null) return <Four0Four />;
  else if (item && item.isForbiddenForSale)
    return (
      <div id="forbidden">
        <h1>{"This release is forbidden on the Common Ground marketplace"}</h1>
      </div>
    );
  else if (!item) return null;

  const layout = theme?.pages?.item?.layout?.id || "twoColumnsLeft";

  let CompomemtToShow;
  if (isMobile)
    CompomemtToShow = <MobileVersion item={item} theme={theme} addNotification={addNotification} session={session} config={config} />;
  else if (layout === "twoColumnsLeft")
    CompomemtToShow = <TwoColumnsLeft item={item} theme={theme} addNotification={addNotification} session={session} config={config} />;
  else if (layout === "twoColumnsRight")
    CompomemtToShow = <TwoColumnsRight item={item} theme={theme} addNotification={addNotification} session={session} config={config} />;
  else if (layout === "twoColumnsRightB")
    CompomemtToShow = <TwoColumnsRightB item={item} theme={theme} addNotification={addNotification} session={session} config={config} />;
  else if (layout === "threeColumnsLeft" || layout === "threeColumnsCenter")
    CompomemtToShow = (
      <ThreeColumnsGeneric item={item} theme={theme} layout={layout} addNotification={addNotification} session={session} config={config} />
    );

  return (
    <>
      <Meta
        item={item}
        description={item.descriptions.shop ? item.descriptions.shop.short || item.descriptions.shop.text : item.descriptions.main}
        title={item.descriptions.main}
        path={item.path}
        updated={item.created}
        image={item.data.images.length ? item.data.images[0].uri : null}
        id={item.id}
        brand={item.type === "ReleaseItem" ? (item.data.labels?.length ? item.data.labels[0].name : null) : item.data.manufacturer}
      />
      {CompomemtToShow}
    </>
  );
};

const ShareBlock = ({ item }: { item: Item }) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [isSharing, setIsSharing] = useState(false);
  const handleCopyToClipboard = (uri: string) => {
    copy(uri);
    addNotification({ ok: 1, message: t("copiedToClipboard") });
  };

  const image = item.data.images.length ? item.data.images[0].uri : null;

  return (
    <div className="shareButton itemButton" onClick={() => setIsSharing(true)}>
      {!isSharing ? (
        <p>{t("Share")}</p>
      ) : (
        <div className="content">
          <button onClick={() => handleCopyToClipboard(item.uri)}>
            <i className="cg-icon-share-link" />
          </button>
          <FacebookShareButton url={item.uri}>
            <i className="cg-icon-share-facebook" />
          </FacebookShareButton>
          <TwitterShareButton url={item.uri}>
            <i className="cg-icon-share-twitter" />
          </TwitterShareButton>
          {image ? (
            <PinterestShareButton media={image} url={item.uri}>
              <i className="cg-icon-share-pinterest" />
            </PinterestShareButton>
          ) : null}
        </div>
      )}
    </div>
  );
};

const MobileVersion = ({
  item,
  session,
  addNotification,
  config
}: {
  item: Item;
  theme: Theme;
  session?: Session;
  addNotification: AddNotification;
  config: Config;
}) => {
  return (
    <div id="item" className="mobile">
      <div className="content">
        <div className="title">
          <h1>
            {item.type === "ReleaseItem"
              ? item.data.artists?.map((a, index) => (
                  <span key={index + a.name}>
                    {a.id ? <Link to={`/catalogue?artists=${a.id}`}>{a.anv || a.name} </Link> : `${a.anv || a.name}`}
                  </span>
                ))
              : null}
            {item.type === "ProductItem" ? <span>{item.data.manufacturer}</span> : null}
          </h1>
          <h2>{item.data.title}</h2>
        </div>
        <Images item={item} />
        <Buttons item={item} config={config} addNotification={addNotification} session={session} />
        <Specs item={item} />
        <p dangerouslySetInnerHTML={{ __html: item.descriptions.shop?.html || "" }} />
        <Tracklist item={item} />
        {config.eshop.preferences.suggestions?.enabled ? <SuggestionsWithHeader item={item} /> : null}
      </div>
    </div>
  );
};

const TwoColumnsLeft = ({
  item,
  addNotification,
  session,
  config
}: {
  item: Item;
  theme: Theme;
  session?: Session;
  addNotification: AddNotification;
  config: Config;
}) => {
  return (
    <div id="item" className="twoColumnsLeft">
      <div className="content">
        <div className="top">
          <Title item={item} />
        </div>
        <div className="twoColumns">
          <div className="left">
            <Images item={item} />
            <Tracklist item={item} />
          </div>
          <div className="right">
            <Buttons item={item} addNotification={addNotification} session={session} config={config} />
            <Specs item={item} />
            <p dangerouslySetInnerHTML={{ __html: item.descriptions.shop?.html || "" }} />
          </div>
        </div>
      </div>
      {config.eshop.preferences.suggestions?.enabled ? <SuggestionsWithHeader item={item} /> : null}
    </div>
  );
};

const TwoColumnsRight = ({
  item,
  addNotification,
  session,
  config
}: {
  item: Item;
  theme: Theme;
  session?: Session;
  addNotification: AddNotification;
  config: Config;
}) => {
  return (
    <div id="item" className="twoColumnsRight">
      <div className="content twoColumns">
        <div className="left">
          <Title item={item} />
          <Specs item={item} />
          <p dangerouslySetInnerHTML={{ __html: item.descriptions.shop?.html || "" }} />
          <Tracklist item={item} />
        </div>
        <div className="right">
          <Images item={item} />
          <Buttons item={item} addNotification={addNotification} session={session} config={config} />
        </div>
      </div>
      {config.eshop.preferences.suggestions?.enabled ? <SuggestionsWithHeader item={item} /> : null}
    </div>
  );
};

const TwoColumnsRightB = ({
  item,
  addNotification,
  session,
  config
}: {
  item: Item;
  theme: Theme;
  session?: Session;
  addNotification: AddNotification;
  config: Config;
}) => {
  return (
    <div id="item" className="twoColumnsRight">
      <div className="content twoColumns">
        <div className="left">
          <Title item={item} />
          <Buttons item={item} addNotification={addNotification} session={session} config={config} />
          <Specs item={item} />
          <Tracklist item={item} />
        </div>
        <div className="right">
          <Images item={item} />
          <p dangerouslySetInnerHTML={{ __html: item.descriptions.shop?.html || "" }} />
        </div>
      </div>
      {config.eshop.preferences.suggestions?.enabled ? <SuggestionsWithHeader item={item} /> : null}
    </div>
  );
};

const ThreeColumnsGeneric = ({
  item,
  addNotification,
  config,
  session,
  layout
}: {
  item: Item;
  theme: Theme;
  session?: Session;
  addNotification: AddNotification;
  config: Config;
  layout: string;
}) => {
  return (
    <div id="item" className={`threeColumnsGeneric ${layout}`}>
      <div className="content threeColumns">
        <div className="left">
          <Images item={item} />
        </div>
        <div className="middle">
          <Title item={item} />
          <Specs item={item} />
          <p dangerouslySetInnerHTML={{ __html: item.descriptions?.shop?.html || "" }} />
        </div>
        <div className="right">
          <Buttons item={item} addNotification={addNotification} session={session} config={config} />
          <Tracklist item={item} />
        </div>
      </div>
      {config.eshop.preferences.suggestions?.enabled ? <SuggestionsWithHeader item={item} /> : null}
    </div>
  );
};

const SuggestionsWithHeader = ({ item }: { item: Item }) => {
  const { t } = useTranslation();

  return <Suggestions t={t} item={item} />;
};

const LabelNames = ({ item }: { item: Item }) => {
  if (item.data.labels)
    return (
      <>
        {item.data.labels.map((l, index) => (
          <span key={index + l.name}>
            {l.id ? <Link to={`/catalogue?labels=${l.id}`}>{l.name} </Link> : `${l.name}`} ({l.catno}) <br />
          </span>
        ))}
      </>
    );
  else return null;
};

const Specs = ({ item }: { item: Item }) => {
  if (item.type === "ReleaseItem")
    return (
      <div className="specs">
        <p>
          <LabelNames item={item} />
        </p>
        <div className="formats">
          {item.data.formats?.map((f, i) => (
            <span key={i}>
              <span>
                {f.qty}
                {"x"} {f.name}
              </span>
              {f.descriptions
                .filter(d => !!d)
                .map(d => (
                  <span key={d}> {d}</span>
                ))}
              {f.text ? <span>{f.text}</span> : null}
            </span>
          ))}
        </div>
        <div className="styles">
          {item.data.genres?.map(s => (
            <Link key={s} to={`/catalogue?genres=${s}`}>
              {s},{" "}
            </Link>
          ))}
          {item.data.styles?.map((s, idx) => (
            <Link key={s} to={`/catalogue?styles=${s}`}>
              {s}
              {idx < (item.data.styles || []).length - 1 ? "," : ""}{" "}
            </Link>
          ))}
        </div>
        {item.data.releaseDate ? (
          <p>
            {t("releaseDate")}: {DateTime.fromMillis(item.data.releaseDate).toFormat("DD")}
            {item.data.country ? <Link to={`/catalogue?countries=${item.data.country}`}>, {item.data.country}</Link> : null}
          </p>
        ) : null}
      </div>
    );
  else if (item.type === "ProductItem")
    return (
      <div className="specs">
        <p>
          {item.data.manufacturer} {item.data.cat ? `(${item.data.cat})` : ""}
        </p>
      </div>
    );
  else if (item.type === "BookItem")
    return (
      <div className="specs">
        <p>
          {t("publisher")}: {item.data.publisher}
        </p>
        <p>
          {t("pages")}: {item.data.pageCount}
        </p>
        <p>
          {t("categories")}: {item.data.categories?.join(", ")}
        </p>
        <p>
          {t("language")}: {item.data.language}
        </p>
        {item.data.publishedDate ? (
          <p>
            {t("publicationDate")}: {DateTime.fromMillis(item.data.publishedDate).toFormat("DD")}
          </p>
        ) : null}
        {item.data.identifiers?.map(ide => (
          <p key={ide.value}>
            {ide.type}: <span>{ide.value}</span>
          </p>
        ))}
      </div>
    );
  else return null;
};

const Title = ({ item }: { item: Item }) => {
  if (item.type === "ReleaseItem")
    return (
      <div className="title">
        <h1>
          {item.data.artists?.map((a, index) => (
            <span key={index + a.name}>
              {a.id ? <Link to={`/catalogue?artists=${a.id}`}>{a.anv || a.name}</Link> : `${a.anv || a.name}`}
              {index < (item.data.artists || []).length - 1 ? (a.join ? `${a.join !== "," ? " " : ""}${a.join} ` : ", ") : ""}
            </span>
          ))}
        </h1>
        <h2>{item.data.title}</h2>
      </div>
    );
  else if (item.type === "ProductItem")
    return (
      <div className="title">
        <h1>{item.data.manufacturer}</h1>
        <h2>{item.data.title}</h2>
      </div>
    );
  else if (item.type === "BookItem")
    return (
      <div className="title">
        <h1>
          {item.data.title} {item.data.subtitle ? <span> - {item.data.subtitle}</span> : null}
        </h1>
        <h2>{item.data.authors ? item.data.authors.join(", ") : ""}</h2>
      </div>
    );
  else return null;
};

const Buttons = ({
  item,
  config,
  session,
  addNotification
}: {
  item: Item;
  config: Config;
  session?: Session;
  addNotification: AddNotification;
}) => {
  const { t } = useTranslation();
  const [downloadReleaseSnippets] = useMutation(POST_RELEASE_SNIPPET_ARCHIVE);
  const [isGeneratingZip, setIsGeneratingZip] = useState(false);
  const isRelease = item.type === "ReleaseItem";
  const wantlistEnabled = config.eshop.preferences?.wantlist?.enabled;

  const handleDownloadSnippet = async () => {
    addNotification({ ok: 1, message: "Please wait while generating your zip file" });
    setIsGeneratingZip(true);
    try {
      const { data } = await downloadReleaseSnippets({ variables: { id: item.id } });
      if (data?.itemSnippetsArchive) window.location = data.itemSnippetsArchive as unknown as Location;
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
    setIsGeneratingZip(false);
  };

  return (
    <div className="buttons">
      {item.listings.map(l => (
        <AddToBasketButton key={l.id} addNotification={addNotification} item={item} listing={l} />
      ))}
      {wantlistEnabled ? <AddToWantlist item={item} /> : null}
      <ShareBlock item={item} />
      {item.data.assetLink ? (
        <div className="itemButton">
          <a target="_tab" href={item.data.assetLink}>
            {t("downloadAssets")}
          </a>
        </div>
      ) : null}
      {isRelease && session && getConfigProperty(config, "information", "snippetDownload") ? (
        <button onClick={handleDownloadSnippet} className="snippetDownload itemButton" disabled={isGeneratingZip}>
          {isGeneratingZip ? "..." : "Download audio"}
        </button>
      ) : null}
    </div>
  );
};

const AddToBasketButton = ({ item, listing, addNotification }: { item: Item; listing: Listing; addNotification: AddNotification }) => {
  const { t } = useTranslation();

  const { updateBasket, config } = Store.useState(s => s) as { updateBasket: any; config: Config };
  const { hasInBasket } = useCheckout();

  const handleAddToBasket = async () => {
    if (listing.stock.quantity === 0) return addNotification({ ok: 0, message: t("soldOut") });
    await updateBasket(listing.id);
  };

  const available = listing.stock.quantity > 0;

  const OptionsDisplayer = ({ item, options }: { item: Item; options: Option[] }) => {
    const isRelease = item.type === "ReleaseItem";

    if (isRelease) {
      const mediaCondition = options.find(o => o.name === "Media Condition");
      const sleeveCondition = options.find(o => o.name === "Sleeve Condition");
      const otherOptions = options.filter(o => o.name !== "Sleeve Condition" && o.name !== "Media Condition");
      if (mediaCondition) {
        const mediaValue = mediaCondition && mediaCondition.value;
        const sleeveValue = sleeveCondition && sleeveCondition.value;
        return (
          <div className="options">
            <div className="option mediaAndSleeve">
              {mediaValue ? (
                <>
                  <span className="media">{t("media")}:</span>
                  <span className="value">{mediaValue}</span>
                </>
              ) : null}
              <span className="separator"> / </span>
              {sleeveValue ? (
                <>
                  <span className="sleeve">{t("sleeve")}:</span>
                  <span className="value">{sleeveValue}</span>
                </>
              ) : null}
            </div>
            {otherOptions.map(o => (
              <div key={o.name} className={"option " + o.name.toLowerCase()}>
                <span>
                  {o.name}: {o.value}
                </span>
              </div>
            ))}
          </div>
        );
      } else return null;
    } else {
      return (
        <div className="options">
          {options.map(o => (
            <div key={o.name} className={"option " + o.name.toLowerCase()}>
              <span>
                {o.name}: {o.value}
              </span>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <button className={`buyButton itemButton ${available ? "available" : ""}`} onClick={() => available && handleAddToBasket()}>
      <div className="top">
        <div className="prices">
          <Price listing={listing} />
          {listing.prices.compare ? <Price className="discounted" value={listing.prices.compare} /> : null}
        </div>
        {available ? (
          <>
            <span>{hasInBasket(listing.id) ? t("removeFromBasket") : t("addToBasket")}</span>
          </>
        ) : (
          <p>{t("soldOut")}</p>
        )}
      </div>
      <div className="bottom">
        {listing.preOrder ? (
          <div className="preorderNotice">
            {t("preOrderState")}{" "}
            {listing.available ? (
              <>
                {t("available")} {DateTime.fromMillis(listing.available).toFormat("DD")}
              </>
            ) : null}
          </div>
        ) : null}
        <OptionsDisplayer options={listing.options} item={item} />
        <div className="listingComments">{listing.comments ? <p> {listing.comments} </p> : null}</div>
        <div className="taxNotice">
          <p> {config.taxes.rules.showPricesBeforeTaxes ? t("taxNoticeExcluded") : t("taxNotice")} </p>
        </div>
      </div>
    </button>
  );
};

const AddToWantlist = ({ item }: { item: Item }) => {
  const { addToWantlist } = useWantlist();
  const { t } = useTranslation();

  return (
    <div className="wantlistButton itemButton" onClick={() => addToWantlist(item)}>
      {item.wanted ? t("removeFromWantlist") : t("addToWantlist")}
    </div>
  );
};

const Tracklist = ({ item }: { item: Item }) => {
  const isRelease = item.type === "ReleaseItem";

  return (
    <>
      {isRelease ? (
        <div className="tracklist">
          {item.data.tracklist
            ?.filter(t => t.type_ === "track")
            .map((t, index) => (
              <PlayerButtonDetailed key={`${t.position}-${index}`} track={t} release={item} />
            ))}
        </div>
      ) : null}{" "}
    </>
  );
};

export const PlayerButtonDetailed = ({ track, release }: { track: Track; release: Item }) => {
  const { playPauseTrack, currentTrackData, isPlaying, loadTracklist } = PlayerStore.useState(s => s);
  const config = Store.useState(s => s.config) as Config;
  const active = config.eshop.preferences.player?.enabled;

  const buttonIsPlaying = isPlaying && currentTrackData && currentTrackData.track.uri === track.uri;

  const hasSnippet = !!track.uri;
  const tracklist = release.data.tracklist?.filter(t => t.uri) || [];
  const position = tracklist?.findIndex(t => t.uri === track.uri) || 0;

  const tracklistToLoad = {
    tracklist: tracklist?.map(t => ({ track: t, release })),
    playOffset: position
  };

  const handleClick = () => {
    if (!hasSnippet || !playPauseTrack || !loadTracklist) return;
    if (buttonIsPlaying) playPauseTrack();
    else loadTracklist(tracklistToLoad);
  };

  const PlayButton = () =>
    !hasSnippet ? (
      <span>
        <i className="cg-icon-player-outline" />
      </span>
    ) : (
      <button disabled={!track.uri} className="play">
        {buttonIsPlaying ? <i className="cg-icon-pause" /> : <i className="cg-icon-play" />}
      </button>
    );

  return (
    <div key={track.position} onClick={handleClick} className={`track ${buttonIsPlaying ? "playing " : ""}${track.uri ? "playable " : ""}`}>
      <div className="left">
        {active ? <PlayButton /> : null}
        <p className="position">
          <span>{track.position}</span>
        </p>
        <p className="description">
          <span>
            {track.artists.length ? (track.artists[0].anv || track.artists[0].name) + " - " : null}
            {track.title}
          </span>
        </p>
      </div>
      <p className="duration">
        <span>{track.duration}</span>
      </p>
    </div>
  );
};

const Images = ({ item }: { item: Item }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  return (
    <div className="images">
      <Image className="main" alt={`${item.descriptions.main} - main`} image={item.data?.images[currentImageIndex]} />
      <div className="thumbnails">
        {item.data.images.map((i, idx) => (
          <Image image={i} alt={`${item.descriptions.main} - ${idx + 1}`} onClick={() => setCurrentImageIndex(idx)} key={i.uri} />
        ))}
      </div>
    </div>
  );
};

const Loader = async (props: { params: { id: string } }) => {
  return { data: { id: parseInt(props.params.id) } };
};

const Routes = [
  {
    path: "/release/:id/:slug?",
    element: <ItemComponent />,
    loader: Loader
  },
  {
    path: "/item/:id/:slug?",
    element: <ItemComponent />,
    loader: Loader
  },
  {
    path: "/product/:id/:slug?",
    element: <ItemComponent />,
    loader: Loader
  },
  {
    path: "/book/:id/:slug?",
    element: <ItemComponent />,
    loader: Loader
  }
];

export default ItemComponent;
export { Routes };
