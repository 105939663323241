import React from "react";
import { Helmet } from "react-helmet";
import { Store } from "../store";
import ReactGA4 from "react-ga4";
import { DateTime } from "luxon";
import { helmetJsonLdProp } from "react-schemaorg";
import { getConfigProperty } from "../utils";
import { Config, Item } from "../__generated__/graphql";

function truncate(string: string, num: number) {
  if (!num) num = 150;
  string = string.replace(/"/, " ");
  if (string.length > num) {
    return string.substring(0, num - 3) + " ...";
  } else return string;
}

export default function Meta({
  title: titleProp,
  noIndex,
  item,
  path,
  description,
  brand,
  updated,
  fourOFour,
  image,
  id
}: {
  title?: string | null;
  noIndex?: boolean;
  item?: Item | null;
  path?: string | null;
  description?: string | null;
  brand?: string | null;
  updated?: number;
  fourOFour?: boolean;
  image?: string | null;
  id?: number;
}) {
  const config = Store.useState(s => s.config) as Config;
  const canonical = path !== undefined ? "https://" + config.domain + path : null;
  const title = titleProp
    ? `${titleProp} - ${getConfigProperty(config, "information", "shopName")}`
    : getConfigProperty(config, "information", "shopName");
  const defaultImage = getConfigProperty(config, "designs", "logoImage");
  const introduction = getConfigProperty(config, "information", "introduction");
  const descriptionToUse = description || `${title} - ${introduction}`;

  let indexValue = "index";
  if (noIndex || getConfigProperty(config, "hosting", "noindex")) indexValue = "noindex";

  /* eslint-disable-next-line quotes */
  let indexMetaSelector = document.querySelector('meta[name="robots"]');
  if (!indexMetaSelector) {
    indexMetaSelector = document.createElement("meta");
    indexMetaSelector.setAttribute("name", "robots");
    indexMetaSelector = document.getElementsByTagName("head")[0].appendChild(indexMetaSelector);
  }
  indexMetaSelector.setAttribute("content", indexValue);

  if (ReactGA4.isInitialized) ReactGA4.set({ title });

  const jsonLd =
    item?.listings?.map(l =>
      helmetJsonLdProp({
        // @ts-expect-error "https://schema.org/"
        "@context": "https://schema.org/",
        "@type": "Product",
        name: item.descriptions.main,
        image: image || defaultImage,
        description,
        mpn: item.id,
        brand: {
          "@type": "Thing",
          name: brand
        },
        offers: {
          "@type": "Offer",
          priceCurrency: config.currency,
          price: l.prices.sale,
          itemCondition: l.secondHand ? "http://schema.org/UsedCondition" : "http://schema.org/NewCondition",
          availability: l.preOrder
            ? "http://schema.org/PreOrder"
            : l.stock.quantity
            ? "http://schema.org/InStock"
            : "http://schema.org/OutOfStock",
          seller: {
            "@type": "Organization",
            name: config.shopName
          }
        }
      })
    ) || [];

  const listing = item?.listings[0];

  return (
    <Helmet script={[...jsonLd]}>
      <title>{title}</title>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {canonical ? <meta property="og:url" content={canonical}></meta> : null}
      <meta name="keywords" content={title.replace(/[^a-zA-Z ]/g, "").replace(/ /g, ",")} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={getConfigProperty(config, "information", "shopName")} />
      {updated ? <meta property="og:updated_time" content={String(DateTime.fromMillis(updated).toSeconds())} /> : null}
      {descriptionToUse ? <meta name="description" content={truncate(descriptionToUse, 150)} /> : null}
      {fourOFour ? <meta name="prerender-status-code" content="404" /> : null}
      {descriptionToUse ? <meta property="og:description" content={truncate(descriptionToUse, 200)} /> : null}
      <meta property="og:image" itemProp="image" content={image || defaultImage} />
      {brand ? <meta property="product:brand" content={brand} /> : null}
      {listing ? <meta property="product:availability" content={listing.stock.quantity > 0 ? "in stock" : "oos"} /> : null}
      {listing ? <meta property="product:condition" content="new" /> : null}
      {listing ? <meta property="product:price:amount" content={String(listing.prices.sale)} /> : null}
      {listing ? <meta property="product:price:currency" content={getConfigProperty(config, "information", "currency")} /> : null}
      {id ? <meta property="product:retailer_item_id" content={String(id)} /> : null}
    </Helmet>
  );
}
