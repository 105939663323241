import { useQuery, useLazyQuery } from "@apollo/client";
import { apolloClient } from "../apolloClient";
import { Store } from "../store";
import { getConfigProperty } from "../utils";
import { GET_COLLECTION, GET_INVENTORY, GET_ITEM, GET_ITEM_SUGGESTIONS, GET_PLAYLIST } from "../graphql/queries/inventory";
import { CollectionQueryVariables, Config } from "../__generated__/graphql";

export const usePlaylist = ({ id }: { id: number }) => {
  const [getPlaylist, { data, loading }] = useLazyQuery(GET_PLAYLIST, { variables: { id } });
  const playlist = data?.playlist;

  return { getPlaylist, playlist, loading };
};

export const useCollection = (options: CollectionQueryVariables) => {
  const [getCollection, { data, loading }] = useLazyQuery(GET_COLLECTION, { variables: options });
  const collection = data?.collection;

  return { getCollection, collection, loading };
};

export const useDefaultImage = () => {
  const config = Store.useState(s => s.config);

  return getConfigProperty(config as Config, "designs", "missingImageUri");
};

const itemLoader = async (id: number) => {
  return apolloClient.query({ query: GET_ITEM, variables: { id } });
};

const collectionLoader = async (id: number) => {
  return apolloClient.query({ query: GET_COLLECTION, variables: { id } });
};

const useInventory = (options = {}) => {
  const { data, loading, error } = useQuery(GET_INVENTORY, { variables: options });

  return { data, loading, error };
};

const useSuggestions = (options = {}) => {
  const { data, loading, error } = useQuery(GET_ITEM_SUGGESTIONS, { variables: options });

  return { data, loading, error };
};

export { itemLoader, collectionLoader, useInventory, useSuggestions };
