import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Store } from "../store";
import URI from "urijs";
import Parse from "html-react-parser";
import useResponsive from "../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import useModal from "../hooks/useModal";
import { SubscribeBlock, replaceLink } from "./blocks";
import useNotification, { AddNotification } from "../hooks/useNotification";
import Cookies from "js-cookie";
import { Settings } from "luxon";
import { getConfigProperty, getModalStyles } from "../utils";
import { Config, Theme, ThemeColorPreset } from "../__generated__/graphql";
import { i18n } from "i18next";

const Menu = ({ location = "navbar", layout }: { location?: string; layout?: string | null }) => {
  const { cookies, config } = Store.useState(s => s) as { cookies: any; config: Config };
  const theme = Store.useState(s => s.theme) as Theme;

  const currentLocation = useLocation();
  const { addNotification } = useNotification();
  const { t, i18n } = useTranslation();
  const settings = theme.settings;
  const { isMobile } = useResponsive({ maxWidth: settings?.mobileBreakpoint || 920 });

  const menu = config.eshop?.menus?.find(m => m.location === location);
  if (!menu) return <span className={`menu ${location}`} />;

  const getElementForEntry = ({ entry, i }: { entry: any; i: number }) => {
    switch (entry.type) {
      case "html": {
        return (
          <div className={`html ${entry.className || ""}`} key={i}>
            {Parse(entry.data.html || "", { replace: replaceLink })}
          </div>
        );
      }
      case "image": {
        const Image = <img className={entry.type} src={entry.data.src} alt={entry.data.alt} />;
        const className = `image ${entry.className || ""}`;
        const isExternal = entry.data?.to && new URI(entry.data.to).is("absolute");
        if (entry.data?.to)
          return !isExternal ? (
            <Link key={i} id={entry.id || ""} className={className} to={entry.data?.to || "/"}>
              {Image}
            </Link>
          ) : (
            <a
              key={i}
              id={entry.id}
              className={`link ${entry.className || ""}`}
              target="_blank"
              rel="noopener noreferrer"
              href={entry.data.to}
            >
              {Image}
            </a>
          );
        else
          return (
            <div key={i} id={entry.id || ""} className={className}>
              {Image}
            </div>
          );
      }
      case "dropdown":
        return (
          <Dropdown
            key={i}
            entry={entry}
            isNavbarAndLeftAligned={isNavbarAndLeftAligned}
            backgroundColor={!isMobile && location === "header" ? settings?.header?.backgroundColor : settings?.backgroundColor}
            isMobile={isMobile}
            getElementForEntry={getElementForEntry}
            location={currentLocation.pathname + currentLocation.search}
          />
        );
      case "link": {
        const isExternal = entry.data.to && new URI(entry.data.to).is("absolute");
        const isActive = currentLocation.pathname === entry.data.to;
        if (!isExternal)
          return (
            <Link key={i} className={`link${isActive ? " active" : ""}`} to={entry.data.to}>
              {t(entry.data.key)}
            </Link>
          );
        else
          return (
            <a key={i} id={entry.id} className="link external" target="_blank" rel="noopener noreferrer" href={entry.data.to}>
              {entry.data.key}
            </a>
          );
      }
      case "group":
        return (
          <nav key={`group-${i}`} className={`group ${entry.className || ""}`}>
            {entry.entries.map((e: any, i: number) => getElementForEntry({ entry: e, i }))}
          </nav>
        );
      case "subscribe": {
        const listRef = getConfigProperty(config, "newsletter", "defaultListRef");
        if (!listRef) return null;
        return <Subscribe key={i} config={config} theme={theme} isMobile={isMobile} listRef={listRef} addNotification={addNotification} />;
      }
      case "privacyConsent": {
        return (
          <button type="button" className="openCookies" key={i} onClick={() => cookies.openModal()}>
            {"Cookies"}
          </button>
        );
      }
      case "languageSelector": {
        return <LanguageSelector key={i} styles={menuStyle} config={config} i18n={i18n} />;
      }
      case "socialNetworks": {
        return <SocialNetworks key={i} config={config} />;
      }
      default:
        return null;
    }
  };

  const menuLocation = menu.location;
  const menuStyle: CSSProperties = {};
  const isNavbarAndLeftAligned = location === "navbar" && settings?.layout?.id === "leftAligned";
  const isHeaderSticky = !!settings?.header?.sticky;
  const isSticky = isNavbarAndLeftAligned && !isHeaderSticky;

  if (menuLocation === "footer" && settings?.footer?.backgroundColor)
    menuStyle.backgroundColor = `var(--${settings?.footer.backgroundColor.id})`;
  else if (menuLocation === "topHeader" && settings?.superHeader?.backgroundColor)
    menuStyle.backgroundColor = `var(--${settings?.superHeader.backgroundColor.id})`;

  const elements = menu.entries.map((entry: any, i: number) => getElementForEntry({ entry, i }));

  if (!elements.length) return null;
  return (
    <nav
      id={menu.id}
      style={menuStyle}
      className={`menu ${isSticky ? "sticky" : ""} ${menu.className} ${menu.location} ${layout || ""} ${isMobile ? "mobile" : ""}`}
    >
      <div className="content">{elements}</div>
    </nav>
  );
};

export const Dropdown = ({
  entry,
  isMobile,
  getElementForEntry,
  location,
  backgroundColor,
  isNavbarAndLeftAligned
}: {
  entry: any;
  isMobile: boolean;
  getElementForEntry: ({ entry, i }: { entry: any; i: number }) => ReactElement | null;
  location: string;
  backgroundColor?: ThemeColorPreset | null;
  isNavbarAndLeftAligned: boolean;
}) => {
  const { t } = useTranslation();
  const { data } = entry;
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<any>(null);

  const handleClick = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !isNavbarAndLeftAligned) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isNavbarAndLeftAligned) setIsOpen(false);
  }, [location, isNavbarAndLeftAligned]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div className={`dropdown ${entry.className || ""} ${isMobile ? "mobile" : ""}`} ref={wrapperRef}>
      <div className={`${data.main.className || ""} top`}>
        {data.main.data.to && data.main.data.to !== "/" ? (
          <Link to={data.main.data.to}>
            {data.main.data.key && t(`${data.main.data.key}`, data.main.data.key)}
            <button type="button">
              <i
                className="cg-icon-down"
                onClick={e => {
                  e.preventDefault();
                  setIsOpen(!isOpen);
                }}
              />
            </button>
          </Link>
        ) : (
          <button type="button" className="navItem" onClick={() => setIsOpen(!isOpen)}>
            {data.main.data.key && t(`${data.main.data.key}`, data.main.data.key)}
            <i className="cg-icon-down" />
          </button>
        )}
      </div>
      <div
        className={`subMenu ${isNavbarAndLeftAligned ? "sticky" : ""} ${isOpen ? "open" : ""}`}
        style={{ backgroundColor: `var(--${backgroundColor?.id})` }}
      >
        <ul>
          {data.entries &&
            data.entries.map((i: any, idx: number) => (
              <li className="entry" key={idx}>
                {getElementForEntry({ entry: i, i: idx })}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export const Subscribe = ({
  config,
  listRef,
  isMobile,
  addNotification,
  theme
}: {
  config: Config;
  listRef: string;
  isMobile: boolean;
  addNotification: AddNotification;
  theme: Theme;
}) => {
  const { t } = useTranslation();
  const { Modal, useModalComponent } = useModal();
  const [isOpen, setIsOpen] = useState(false);
  const styles = getModalStyles(theme);

  const defaultListRef = listRef || getConfigProperty(config, "newsletter", "defaultListRef");

  useEffect(() => {
    if (isOpen) showModal();
    else hideModal();
  }, [isOpen]);

  const [showModal, hideModal] = useModalComponent(
    () => (
      <Modal ariaHideApp={false} isOpen={isOpen} style={styles} onRequestClose={() => setIsOpen(false)}>
        <div id="subscribeModal">
          <SubscribeBlock isMobile={isMobile} listRef={defaultListRef} addNotification={addNotification} />
        </div>
      </Modal>
    ),
    [isMobile, isOpen, styles, defaultListRef]
  );

  return (
    <button type="button" onClick={() => setIsOpen(true)}>
      {t("subscribe")}
    </button>
  );
};

const LanguageSelector = ({ config, i18n, styles }: { config: Config; i18n: i18n; styles: CSSProperties }) => {
  const currentLanguage = i18n.language;
  const languages = config.eshop.preferences?.languages?.whitelist || [];
  const [open, setOpen] = useState(false);
  const localRef = useRef<any>();

  const handleClickOutside = (event: any) => {
    if (localRef.current && !localRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => document.removeEventListener("click", handleClickOutside, true);
  }, []);

  const handleChangeLanguage = (locale: string) => {
    Cookies.set("locale", locale, { expires: 365 });
    Settings.defaultLocale = locale;
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    if (languages.indexOf(currentLanguage) === -1) {
      if (languages.length) handleChangeLanguage(languages[0]);
      else handleChangeLanguage("en");
    }
  }, [config.eshop.preferences?.languages?.whitelist, currentLanguage]);

  if (languages.length < 2) return null;

  return (
    <div className="languageSelector" ref={localRef}>
      <div className="current">
        <button onClick={() => setOpen(!open)} className="current">
          <i className="cg-icon-languages" />
          {currentLanguage}
          <i className="cg-icon-down" />
        </button>
      </div>
      {open ? (
        <div className="entries" style={styles}>
          {languages
            .filter(l => l !== currentLanguage)
            .map(l => (
              <button
                key={l}
                className={"entry" + (i18n.language === l ? "selected" : "")}
                type="button"
                onClick={() => {
                  setOpen(false);
                  handleChangeLanguage(l);
                }}
              >
                {l}
              </button>
            ))}
        </div>
      ) : null}
    </div>
  );
};

const SocialNetworks = ({ config }: { config: Config }) => {
  return (
    <div className="socialNetworks">
      {config.socials?.map(s => (
        <a
          href={s.id === "mail" ? `mailto:${s.value}` : s.id === "phone" ? `tel:${s.value}` : s.value || ""}
          target="_tab"
          key={s.id}
          className="socialNetwork"
        >
          <i className={`cg-icon-${s.id}`} />
        </a>
      ))}
    </div>
  );
};

export default Menu;
export { LanguageSelector };
