import React from "react";
import { Store } from "../store";
import useTranslation from "../hooks/useTranslation";
import Meta from "../components/meta";
import { getConfigProperty } from "../utils";
import { Config } from "../__generated__/graphql";

const Terms = () => {
  const config = Store.useState(s => s.config) as Config;
  const { t } = useTranslation();

  return (
    <div id="terms">
      <Meta title="Terms and conditions" description="Our terms and conditions" />
      <div className="header">
        <h1>{t("termsAndConditions")}</h1>
      </div>
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: getConfigProperty(config, "information", "legalText")
        }}
      ></div>
    </div>
  );
};

const ShippingPolicies = () => {
  const config = Store.useState(s => s.config) as Config;
  const { t } = useTranslation();

  return (
    <div id="shippingPolicies">
      <Meta title="Shipping" description="Our shipping policies" />
      <div className="header">
        <h1>{t("shipping")}</h1>
      </div>
      <div className="content">
        <div id="content" dangerouslySetInnerHTML={{ __html: getConfigProperty(config, "shipping", "policyText") }} />
      </div>
    </div>
  );
};

const Routes = [
  {
    path: "/legal",
    element: <Terms />,
    loader: null
  },
  {
    path: "/shipping",
    element: <ShippingPolicies />,
    loader: null
  }
];

export { Routes };
