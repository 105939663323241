import React from "react";
import { useSuggestions } from "../hooks/useInventory";
import { Tile } from "./views";
import { Store } from "../store";
import { Item, Theme } from "../__generated__/graphql";
import { TFunction } from "i18next";

const Suggestions = ({ item, t }: { item: Item; t: TFunction }) => {
  const theme = Store.useState(s => s.theme) as Theme;

  const columns = theme?.settings?.collectionColumns?.desktop || 6;
  const { data, loading } = useSuggestions({
    type: item ? item.type : "ReleaseItem",
    limit: columns,
    genres: item ? item.data.genres : [],
    id: item ? item.id : null
  });

  const suggestions = data && data.suggestions;

  if (!data || loading || !suggestions) return null;

  return (
    <div className="suggestions">
      <h3>{t("suggestionsHeader")}</h3>
      <div className="content">
        {suggestions.map(s => (
          <Tile key={s._id} item={s} />
        ))}
      </div>
    </div>
  );
};

export default Suggestions;
