import React, { useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
import { PlayerStore, Store, TracklistToLoad } from "./store";
import { useMutation } from "@apollo/client";
import useResponsive from "./hooks/useResponsive";
import gql from "graphql-tag";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Theme } from "./__generated__/graphql";

export default () => {
  const { isMobile } = useResponsive();
  const theme = Store.useState(s => s.theme) as Theme;
  const { tracklist, isPlaying, position, shouldPlayNext, shouldPlayPrevious, currentTrackData, isLoading, isShowing } =
    PlayerStore.useState(s => s);

  const wavesurferRef = useRef<any>();

  const settings = {
    normalize: true,
    hideScrollbar: true,
    container: "#waveform",
    backend: "WebAudio",
    waveColor: theme.player?.waveformColor ? theme.player?.waveformColor.value : "#bbb",
    progressColor: theme.player?.progressColor ? theme.player?.progressColor.value : "#000",
    cursorColor: theme.player?.cursorColor ? theme.player?.cursorColor.value : "#ccc",
    cursorWidth: theme.player?.cursorWidth || 0,
    height: 15,
    responsive: true,
    barRadius: 1,
    barWidth: 1,
    barGap: 1
  };

  if (theme.player?.barRadius) settings.barRadius = theme.player?.barRadius;
  if (theme.player?.barWidth) settings.barWidth = theme.player?.barWidth;
  if (theme.player?.barGap) settings.barGap = theme.player?.barGap;
  if (theme.player?.cursorWidth) settings.cursorWidth = theme.player?.cursorWidth;

  const [playCount] = useMutation(POST_PLAY_COUNT);

  const handleWSMount = useCallback((waveSurfer: any) => {
    wavesurferRef.current = waveSurfer;

    wavesurferRef.current.on("ready", () => {
      wavesurferRef.current.play();
    });

    wavesurferRef.current.on("play", () => {
      PlayerStore.update(s => {
        s.isPlaying = true;
      });
    });
    wavesurferRef.current.on("finish", () => {
      PlayerStore.update(s => {
        s.shouldPlayNext = true;
      });
    });

    wavesurferRef.current.on("loading", (percent: number) => {
      PlayerStore.update(s => {
        s.isLoading = percent !== 100;
      });
    });

    if (window) {
      // window.surferidze = wavesurferRef.current;
    }
  }, []);

  const handlePrevious = () => {
    PlayerStore.update(s => {
      s.shouldPlayPrevious = true;
    });
  };

  const handleNext = () => {
    PlayerStore.update(s => {
      s.shouldPlayNext = true;
    });
  };

  const loadTracklist = useCallback(
    (tracklistToLoad: TracklistToLoad) => {
      wavesurferRef.current.stop();
      PlayerStore.update(s => {
        s.isShowing = true;
        s.position = tracklistToLoad.playOffset || 0;
        s.tracklist = tracklistToLoad.tracklist;
      });
    },
    [wavesurferRef.current]
  );

  useEffect(() => {
    if (wavesurferRef.current && position > -1 && position < tracklist.length && tracklist[position].track.uri) {
      const trackToPlay = tracklist[position];
      wavesurferRef.current.load(trackToPlay.track.uri);
      PlayerStore.update(s => {
        s.isLoading = true;
        s.isShowing = true;
        s.currentTrackData = { ...trackToPlay };
      });
      playCount({ variables: { id: trackToPlay.release.id, position: trackToPlay.track.position } }).catch(console.error);
    }
  }, [tracklist, playCount, position, wavesurferRef.current]);

  const playPauseTrack = useCallback(() => {
    wavesurferRef.current.playPause();
    PlayerStore.update(s => {
      s.isPlaying = wavesurferRef.current.isPlaying();
    });
  }, [wavesurferRef.current]);

  const stopAndClose = useCallback(() => {
    wavesurferRef.current.stop();
    PlayerStore.update(s => {
      s.isPlaying = false;
      s.isShowing = false;
    });
  }, [wavesurferRef]);

  useEffect(() => {
    PlayerStore.update(s => {
      s.stopAndClose = stopAndClose;
      s.loadTracklist = loadTracklist;
      s.playPauseTrack = playPauseTrack;
    });
  }, [loadTracklist, playPauseTrack, stopAndClose]);

  useEffect(() => {
    if (!shouldPlayNext && !shouldPlayPrevious) return;

    if (shouldPlayNext) {
      PlayerStore.update(s => {
        s.shouldPlayNext = false;
        s.position = position < tracklist.length - 1 ? position + 1 : position;
      });
    } else if (shouldPlayPrevious) {
      PlayerStore.update(s => {
        s.shouldPlayPrevious = false;
        s.position = position - 1 >= 0 ? position - 1 : position;
      });
    }
  }, [position, shouldPlayNext, shouldPlayPrevious, tracklist.length]);

  let title =
    currentTrackData && currentTrackData.track.title ? `${currentTrackData.track.position} - ${currentTrackData.track.title}` : null;
  if (title && isMobile && title.length > 20) title = title.substring(0, 20) + "...";

  return (
    <div
      id="player"
      className={`${currentTrackData && isShowing ? "" : "hidden"}`}
      style={{ backgroundColor: theme.player?.backgroundColor ? `var(--${theme.player.backgroundColor.id})` : "inherit" }}
    >
      <div className={`content ${isMobile ? "mobile" : ""}`}>
        {currentTrackData && currentTrackData.release ? (
          <div className="left">
            <div className="artworkAndTitle">
              <LazyLoadImage
                alt={currentTrackData.release.descriptions.main || ""}
                src={currentTrackData.release.data?.images[0]?.uri || ""}
              />
              <p className="playerTitle">
                <Link to={currentTrackData.release.path || ""}>{title}</Link>
              </p>
            </div>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div className="controls">
                <button type="button" onClick={handlePrevious} disabled={position === 0}>
                  <i className="cg-icon-player-prev" />
                </button>
                <button className="playPauseButton" onClick={playPauseTrack}>
                  {isPlaying ? <i className="cg-icon-pause" /> : <i className="cg-icon-play" />}
                </button>
                <button type="button" onClick={() => handleNext()}>
                  <i className="cg-icon-player-next" />
                </button>
              </div>
            )}
          </div>
        ) : null}
        <WaveSurfer {...settings} onMount={handleWSMount}>
          <WaveForm id="waveform" />
        </WaveSurfer>
        <div className="right">
          <span>
            {position + 1} / {tracklist.length}
          </span>
          {currentTrackData ? (
            <button onClick={stopAndClose}>
              <i className="cg-icon-close" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const POST_PLAY_COUNT = gql(`
  mutation($id: Float!, $position: String!) {
    playCount(id: $id, position: $position)
  }
`);
