import { useMutation } from "@apollo/client";
import { Store } from "../store";
import useNotification from "./useNotification";
import { Item } from "../__generated__/graphql";
import { POST_WANT } from "../graphql/queries/inventory";

const useWantlist = () => {
  const { session } = Store.useState(s => s);
  const { addNotification } = useNotification();

  const [addToWantlist] = useMutation(POST_WANT);

  const handleAddToWantlist = (item: Item) => {
    if (!item) return;
    if (!session) return addNotification({ ok: 0, message: "Please login to your account" });
    addToWantlist({ variables: { id: item.id as number } });
    addNotification({ ok: 1, message: `Item ${!item.wanted ? "added to" : "removed from"} wantlist` });
  };

  return { addToWantlist: handleAddToWantlist };
};

// gql(`
//   fragment ItemFields on Item {
//     id
//     type
//     path
//     wanted
//     uri
//     isForbiddenForSale
//     listings {
//       id
//       available
//       preOrder
//       secondHand
//       stock {
//         quantity
//       }
//       prices {
//           beforeTaxes
//           sale
//           compare
//       }
//       onePerCustomer
//       options {
//           name
//           value
//       }
//       comments
//     }
//     descriptions {
//       main
//       shop {
//           text
//           html
//           short
//       }
//     }
//     data {
//       title
//       identifiers {
//         type
//         value
//       }
//       images {
//           uri
//           alternative {
//             uri
//           }
//       }
//       assetLink
//       weight
//       genres
//       styles
//       manufacturer
//       cat
//       releaseDate
//       tracklist {
//         type_
//         title
//         artists {
//           name
//           id
//           anv
//         }
//         uri
//         duration
//         position
//       }
//       formats {
//         descriptions
//         name
//         qty
//       }
//       country
//       artists {
//         name
//         anv
//         id
//         join
//       }
//       labels {
//         name
//         id
//         catno
//       }
//       videos {
//         uri
//       }

//       subtitle
//       authors
//       publisher
//       publishedDate
//       pageCount
//       categories
//       maturityRating
//       language
//     }
//   }
// `);

export default useWantlist;
