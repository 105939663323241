import React, { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import Four0Four from "./404";
import { Tile, List, Full } from "../components/views";
import { useQuery } from "@apollo/client";
import Pagination from "../components/pagination";
import URI from "urijs";
import { findUrlForMediaArray } from "../utils";
import useResponsive from "../hooks/useResponsive";
import Meta from "../components/meta";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET_COLLECTION } from "../graphql/queries/inventory";
import { ConfigEshopPreferencesHomepage } from "../__generated__/graphql";

const Collection = ({ id, isHomepage }: { id?: number; isHomepage?: boolean }) => {
  const {
    data: { id: idFromLoader }
  } = useLoaderData() as { data: { id: number } };
  const { isMobile } = useResponsive();
  const currentUri = new URI(location.pathname + location.search);
  const search = currentUri.search(true);
  const currentPage = parseInt(search.page) || 1;
  const [initialFilters, setInitialFilters] = useState({
    page: currentPage,
    order: parseInt(search.order) || undefined,
    sort: search.sort
  });

  useEffect(() => {
    setInitialFilters({ ...initialFilters, page: currentPage, order: parseInt(search.order) || undefined, sort: search.sort });
  }, [location.pathname + location.search]);

  const { data, loading } = useQuery(GET_COLLECTION, { variables: { ...initialFilters, id: id || idFromLoader } });
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (loading) return null;
  else if (!data?.collection) return <Four0Four />;

  const { collection, page } = data.collection;
  const { sort } = collection;
  const View = collection.viewType === "tile" ? Tile : collection.viewType === "list" ? List : collection.viewType === "full" ? Full : Tile;

  const sorts = [
    {
      active: (initialFilters.order || sort?.order) === -1 && (initialFilters.sort || sort?.key) === "price",
      query: { sort: "price", order: -1 },
      name: t("priceDesc")
    },
    {
      active: (initialFilters.order || sort?.order) === 1 && (initialFilters.sort || sort?.key) === "price",
      query: { sort: "price", order: 1 },
      name: t("priceAsc")
    },
    {
      active: (initialFilters.order || sort?.order) === -1 && (initialFilters.sort || sort?.key) === "posted",
      query: { sort: "posted", order: -1 },
      name: t("addedDesc")
    },
    {
      active: (initialFilters.order || sort?.order) === 1 && (initialFilters.sort || sort?.key) === "posted",
      query: { sort: "posted", order: 1 },
      name: t("addedAsc")
    }
  ];

  let sortIndex = sorts.findIndex(s => s.active);
  if (sortIndex < 0) sortIndex = 0;

  const handleSortUpdate = () => {
    const newIndex = (sortIndex + 1) % 4;
    const query = sorts[newIndex].query;
    navigate(
      currentUri
        .clone()
        .setSearch({ ...currentUri.search(true), ...query, page: 1 })
        .toString()
    );
  };

  const className = collection?.handle?.split("/")[3] || "";

  return (
    <div id="collection" className={`${isHomepage ? "homepage" : ""} ${className}`}>
      <Meta
        path={collection.path}
        description={collection.title}
        noIndex={false}
        title={isHomepage ? null : collection.title}
        image={collection.banner?.media ? findUrlForMediaArray(collection.banner.media) : null}
      />
      <div className="header">
        <h1>{collection.title}</h1>
        {collection?.banner?.media ? (
          <div className="banner">
            <LazyLoadImage
              alt={collection.banner?.media?.alt || ""}
              src={findUrlForMediaArray(collection.banner.media, isMobile ? "small" : "banner-free-ratio") || ""}
            />
          </div>
        ) : null}
        {collection?.description ? (
          <div className="description">
            <div dangerouslySetInnerHTML={{ __html: collection.description?.content || "" }} />
          </div>
        ) : null}
        {(page?.pagination?.pages || 1) > 1 ? (
          <div className="sortAndPagination">
            {!isMobile ? (
              <button type="button" className="primary" onClick={handleSortUpdate}>
                {sorts[sortIndex].name}
              </button>
            ) : null}
            <Pagination pagination={page.pagination} currentUri={currentUri} />
          </div>
        ) : null}
      </div>
      <div className={`entries ${collection.viewType || "tile"}View`}>
        {page.items.map(e => (
          <View key={e.id} item={e} />
        ))}
      </div>
      <Pagination pagination={page.pagination} currentUri={currentUri} />
    </div>
  );
};

const Loader = async (props: { params: { id: string } }) => {
  return { data: { id: parseInt(props.params.id) } };
};

const Routes = (homepage?: ConfigEshopPreferencesHomepage | null) => {
  const routes = [
    {
      path: "/collection/:id/:slug",
      element: <Collection />,
      loader: Loader
    }
  ];
  if (homepage?.type === "collection") {
    const uri = new URI(homepage?.handle);
    routes.push({
      path: "/",
      element: <Collection id={parseInt(uri.segment(1) as string)} isHomepage={true} />,
      loader: Loader
    });
  }
  return routes;
};

export default Collection;
export { Routes };
