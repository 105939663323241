import Color from "color";
import { Config, Media, Theme } from "./__generated__/graphql";
import ReactModal from "react-modal";

export const round = (number: number, decimals?: number) => {
  const numberParsed = typeof number === "string" ? parseFloat(number) : number; // to remove
  if (!number || isNaN(numberParsed)) return number;
  return parseFloat(numberParsed.toFixed(decimals || 2));
};

export const findUrlForMediaArray = (media: Media, format?: string) => {
  if (!media || !media.formatArray) return null;
  else if (!format) return media.url;

  const foundFormat = media?.formatArray.find(f => f?.name === format);
  return foundFormat?.url || media.url;
};

export const getConfigProperty = (config: Config, module: string, property: string) => {
  const m = config.modules.find((m: any) => m.id === module);
  if (m && m.data) return m.data[property];
  return null;
};

export const getModalStyles = function (theme: Theme) {
  const settings = theme.settings;
  const backgroundVar = settings?.backgroundColor?.value
    ? `rgba(${Color(settings.backgroundColor?.value).rgb().array().join(",")}, 0.8)`
    : "inherit";

  const styles: ReactModal.Styles = {
    overlay: {
      border: "none",
      backgroundColor: backgroundVar
    },
    content: {
      border: "none",
      backgroundColor: `var(--${settings?.backgroundColor?.id})`,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      minWidth: "30%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  return styles;
};
