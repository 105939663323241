import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Home from "../components/home";
import useResponsive from "../hooks/useResponsive";
import { Store } from "../store";
import URI from "urijs";
import { Theme } from "../__generated__/graphql";

const HomeWrapper = () => {
  const theme = Store.useState(s => s.theme) as Theme;

  const navigate = useNavigate();
  const { isMobile } = useResponsive({ maxWidth: theme.settings?.mobileBreakpoint || 920 });
  const currentUri = new URI(location.pathname + location.search + location.hash);

  const [previousPath] = useState(currentUri.search(true).prev || "/");

  const handleExit = () => {
    navigate(previousPath);
  };

  return <Home exit={handleExit} isMobile={isMobile} />;
};

const Route = [
  {
    path: "/home",
    element: <HomeWrapper />
  }
];

export default Home;
export { Route, HomeWrapper };
