import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import clone from "clone";
import { Store } from "../store";
import useModal from "../hooks/useModal";
import useTranslation from "../hooks/useTranslation";
import { getModalStyles } from "../utils";
import { Theme } from "../__generated__/graphql";
import { TFunction } from "i18next";

const CookiesComponent = () => {
  const { t } = useTranslation();
  const { isMobile } = Store.useState(s => s);
  const theme = Store.useState(s => s.theme) as Theme;
  const { Modal, useModalComponent } = useModal();
  const [agreedToCookies, setAgreedToCookies] = useState(!!Cookies.get("cookies-performance"));
  const cookiesList = [
    {
      id: "performance",
      title: t("cookiesPerformanceTitle"),
      description: t("cookiesPerformanceDescription"),
      active: Cookies.get("cookies-performance") === "true"
    },
    { id: "functional", title: t("cookiesFunctionalTitle"), description: t("cookiesFunctionalDescription"), active: true }
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [cookieSettings, setCookieSettings] = useState<typeof cookiesList>([...cookiesList]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state = cookieSettings;
    // @ts-expect-error because of mess
    state.find(s => s.id === e.target.name).active = e.target.checked;
    setCookieSettings([...state]);
  };

  const acceptSelection = () => {
    const performance = cookieSettings.find(s => s.id === "performance");
    Cookies.set("cookies-performance", performance?.active ? "true" : "false", { expires: 365 });
    setAgreedToCookies(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (agreedToCookies) {
      const performance = cookieSettings.find(s => s.id === "performance");
      if (!performance?.active) {
        Cookies.remove("locale");
        Cookies.remove("_gid");
        Cookies.remove("_ga");
      }
    }
  }, [cookieSettings, agreedToCookies]);

  useEffect(() => {
    Store.update(s => {
      s.cookies = {
        openModal: () => setIsOpen(true)
      };
    });
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookies-performance", "true", { expires: 365 });
    const state = clone(cookieSettings);
    // @ts-expect-error because of mess
    state.find(s => s.id === "performance").active = true;
    setCookieSettings([...state]);
    setAgreedToCookies(true);
    setIsOpen(false);
  };

  const handleRefuseCookies = () => {
    Cookies.set("cookies-performance", "false", { expires: 365 });
    const state = clone(cookieSettings);
    // @ts-expect-error because of mess
    state.find(s => s.id === "performance").active = false;
    setCookieSettings([...state]);
    setAgreedToCookies(true);
    setIsOpen(false);
  };

  const styles = getModalStyles(theme);

  useEffect(() => {
    if (isOpen) showModal();
    else hideModal();
  }, [isOpen]);

  const [showModal, hideModal] = useModalComponent(
    () => (
      <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={styles}>
        <div id="cookiesModal" className={isMobile ? "mobile" : ""}>
          <form onSubmit={e => e.preventDefault()}>
            <h3 className="cookiesTitle">{t("informationWeCollect")}</h3>
            <p>
              {t("cookiesMainDescription")}{" "}
              <Link onClick={() => setIsOpen(false)} to="/legal">
                {t("termsAndConditions")}
              </Link>
            </p>
            <div className="inline">
              <button className="acceptSelection" onClick={acceptSelection}>
                {t("acceptSelection")}
              </button>
              <button className="refuseAll" onClick={handleRefuseCookies}>
                {t("refuseAll")}
              </button>
            </div>
            <label className="cookieOption">
              <input type="checkbox" name="performance" checked={cookieSettings[0].active} onChange={handleCheckboxChange} />
              <div className="cookieDescription optional">
                <p>
                  <strong>{t("cookiesPerformanceTitle")}</strong>
                </p>
                <p>{t("cookiesPerformanceDescription")}</p>
              </div>
            </label>
            <label className="cookieOption">
              <input type="checkbox" disabled name="performance" defaultChecked={true} />
              <div className="cookieDescription functional">
                <p>
                  <strong>{t("cookiesFunctionalTitle")}</strong>
                </p>
                <p>{t("cookiesFunctionalDescription")}</p>
              </div>
            </label>
          </form>
        </div>
      </Modal>
    ),
    [cookieSettings, isOpen, isMobile]
  );

  return (
    <>
      {!agreedToCookies && !isOpen ? (
        <div id="cookies" className={isMobile ? "mobile" : ""}>
          <CookiesSmall
            styles={styles}
            t={t}
            setIsOpen={setIsOpen}
            handleRefuseCookies={handleRefuseCookies}
            acceptCookies={acceptCookies}
          />
        </div>
      ) : null}
    </>
  );
};

const CookiesSmall = ({
  t,
  styles,
  setIsOpen,
  handleRefuseCookies,
  acceptCookies
}: {
  t: TFunction;
  styles: any;
  setIsOpen: (state: boolean) => void;
  handleRefuseCookies: () => void;
  acceptCookies: () => void;
}) => {
  return (
    <div className="content" style={{ backgroundColor: styles.content.backgroundColor }}>
      <p>
        {t("cookiesMainNotice") + " "}
        <br />
        <button type="button" onClick={() => setIsOpen(true)}>
          {t("managePreferences")}
        </button>
      </p>
      <div className="buttons">
        <button className="refuseAll" type="button" onClick={handleRefuseCookies}>
          {t("refuseAll")}
        </button>
        <button className="acceptAll" type="button" onClick={acceptCookies}>
          {t("agreeToAll")}
        </button>
      </div>
    </div>
  );
};
export default CookiesComponent;
