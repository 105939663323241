import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

const useAnalytics = () => {
  const location = useLocation();
  const url = location.pathname + location.search;

  /** * Page view ***/
  useEffect(() => {
    setTimeout(() => {
      console.log("Page view", url, document.title);
      if (ReactGA.ga()) ReactGA.pageview(url);
      if (ReactGA4.isInitialized) ReactGA4.send("pageview");
    }, 500);
  }, [url]);

  useEffect(() => {
    parent.postMessage({ path: location.pathname + location.search }, "*");
  }, [url]);

  return null;
};

export default useAnalytics;
