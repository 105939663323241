import React, { useState, useEffect } from "react";
import URI from "urijs";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { POST_VERIFY } from "../graphql/queries/user";

const Verify = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const params = new URI(location.pathname + location.search).search(true);
  const [verify] = useMutation(POST_VERIFY);

  useEffect(() => {
    verify({ variables: { token: params.token } })
      .then(({ data }) => {
        if (data?.userVerify?.message) setMessage(data.userVerify.message);
      })
      .catch(e => {
        setMessage(e.message);
      });
  }, []);

  return (
    <div id="verify" style={{ textAlign: "center" }}>
      <h1>{message}</h1>
    </div>
  );
};

const Route = {
  path: "/verify",
  element: <Verify />
};

export { Route };
