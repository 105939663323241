import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDefaultImage } from "../hooks/useInventory";
import { Image } from "../__generated__/graphql";

const ItemImage = ({ image, className, id, alt, onClick }: { image: Image; className?: string; id?: string; alt?: string; onClick?: () => void }) => {
  const [isHover, setIsHover] = useState(false);
  const defaultImage = useDefaultImage();

  const imageEntry = image || null;
  let imageSrc = imageEntry?.uri || defaultImage;

  const alternativeSrc = imageEntry?.alternative?.uri;
  if (isHover && alternativeSrc) imageSrc = alternativeSrc;

  return (
    <LazyLoadImage
      className={className || ""}
      id={id}
      alt={alt}
      title={alt}
      src={imageSrc}
      onClick={() => (onClick ? onClick() : null)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
};

export default ItemImage;
