import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apolloClient";
import { Toaster } from "react-hot-toast";
import App from "./app";
import "./i18n";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as HTMLElement);

root.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
      <Toaster />
    </ApolloProvider>
  </StrictMode>
);
