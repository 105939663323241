import { useCallback } from "react";
import toast, { ToastPosition } from "react-hot-toast";

export type AddNotification = ({ ok, message }: { ok: number; message: string }) => void;

export default () => {
  const addNotification = useCallback(({ ok, message }: { ok?: number; message: string }) => {
    const settings = {
      iconTheme: {
        primary: "#000",
        secondary: "#f7f7f7"
      },
      position: "top-right" as ToastPosition,
      autoClose: 1000,
      hideProgressBar: true,
      newestOnTop: false,
      closeOnClick: false,
      theme: "light",
      style: {
        boxShadow: "rgb(0 0 0 / 20%) 0px 5px 10px 0px",
        padding: "10px",
        borderRadius: "40px",
        fontSize: "12px",
        marginTop: "5px"
      }
    };

    if (ok) {
      settings.iconTheme = {
        primary: "#000",
        secondary: "#f7f7f7"
      };
    } else {
      settings.iconTheme = {
        primary: "#ff7d4c",
        secondary: "#ffdcc6"
      };
    }

    if (ok) toast.success(message, settings);
    else toast.error(message, settings);
  }, []);

  return { addNotification };
};
