import { ApolloClient, createHttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";
import URI from "urijs";

const isLocalDev = process.env.NODE_ENV === "development";
const isStaging = process.env.REACT_APP_BUILD_ENV !== "production";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        console.log("AUTH");
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: isLocalDev ? "http://localhost:8881/graphql" : `https://${isStaging ? "staging." : ""}api-client.common-ground.io/graphql`
});
const authLink = setContext((_, { headers = {} }) => {
  const jwt = Cookies.get("jwt");
  if (jwt) headers["commonground-jwt"] = jwt;

  const host = URI(window.location.href).host();
  if (host) headers["commonground-origin"] = host;

  return { headers };
});

const cache = new InMemoryCache({
  typePolicies: {
    ItemLabel: { keyFields: ["catno", "id"] },
    ItemArtist: { keyFields: ["id", "name"] },
    Track: { keyFields: ["title", "position", "uri"] },
    ThemeColorPreset: { keyFields: ["name", "value"] },
    Checkout: {
      fields: {
        items: {
          merge(_, incoming) {
            return incoming;
          }
        }
      }
    }
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache
});

export { apolloClient };
