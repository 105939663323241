import React from "react";
import { Store } from "../store";
import useResponsive from "../hooks/useResponsive";
import Menu from "./menu";
import { Config, Theme } from "../__generated__/graphql";

const SuperHeader = () => {
  const config = Store.useState(s => s.config) as Config;
  const theme = Store.useState(s => s.theme) as Theme;
  const settings = theme.settings;
  const { isMobile } = useResponsive({ maxWidth: settings?.mobileBreakpoint || 920 });

  if (!settings?.superHeader?.display) return null;
  else if (settings?.superHeader?.display) {
    const menu = config.eshop.menus.find(m => m.location === "topHeader");
    if (!menu) return <p>{"Menu not found"}</p>;
    else
      return (
        <div id="topHeader" className={isMobile ? "mobile" : ""}>
          <Menu location="topHeader" />
        </div>
      );
  } else return null;
};

export default SuperHeader;
