import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "https://static.common-ground.io/common/dictionaries/eshop-checkout/production/{{lng}}.json"
    },
    fallbackLng: "en",
    debug: false,
    whitelist: ["en", "fr", "de", "it", "es", "ru"],
    interpolation: { escapeValue: false }
  });

export default i18n;
