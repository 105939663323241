import React, { useState, useEffect } from "react";
import URI from "urijs";
import Meta from "../components/meta";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { POST_LIST_OPTOUT } from "../graphql/queries/user";
import useTranslation from "../hooks/useTranslation";

const Unsubscribe = () => {
  const [reason, setReason] = useState("noLongerWantEmails");
  const [resetMessage, setResetMessage] = useState<string | undefined | null>(undefined);
  const [isUnsubscribe] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const params = new URI(location.pathname + location.search).search(true);
  const [optout] = useMutation(POST_LIST_OPTOUT);

  useEffect(() => {
    if (!params.email || !params.list) setResetMessage(t("Bad url arguments..."));
  }, [params.email, params.list]);

  const handleChangeReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleSubmitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const { data } = await optout({
        variables: {
          email: params.email,
          listRef: params.list,
          reason
        }
      });
      if (data?.listOptout) setResetMessage(data.listOptout);
    } catch (e: any) {
      setResetMessage(e.data || e.toString());
    }
  };

  return (
    <div id="unsubscribe">
      <Meta noIndex={true} />
      <div className="content">
        {resetMessage ? (
          <h2 className="resetMessage">{resetMessage}</h2>
        ) : (
          <>
            {!isUnsubscribe ? (
              <div className="reason">
                <h2 className="resetMessage">{t("Please let us know why you would like to unsubscribe")}:</h2>
                <form onSubmit={handleSubmitForm}>
                  <div className="entries">
                    <label>
                      <input
                        onChange={handleChangeReason}
                        checked={reason === "noLongerWantEmails"}
                        type="radio"
                        name="reason"
                        value="noLongerWantEmails"
                      />
                      {t("I no longer want to receive these emails")}
                    </label>
                    <label>
                      <input
                        onChange={handleChangeReason}
                        checked={reason === "neverSignedUp"}
                        type="radio"
                        name="reason"
                        value="neverSignedUp"
                      />
                      {t("I never signed up for this mailing list")}
                    </label>
                    <label>
                      <input
                        onChange={handleChangeReason}
                        checked={reason === "inappropriate"}
                        type="radio"
                        name="reason"
                        value="inappropriate"
                      />
                      {t("The emails are inappropriate")}
                    </label>
                    <label>
                      <input onChange={handleChangeReason} checked={reason === "spam"} type="radio" name="reason" value="spam" />
                      {t("The emails look like spam")}
                    </label>
                  </div>
                  <div className="submit">
                    <button type="submit" className="primary">
                      {t("Unsubscribe")}
                    </button>
                  </div>
                </form>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

const Route = [
  {
    path: "/unsubscribe",
    element: <Unsubscribe />
  }
];

export default Unsubscribe;
export { Route };
