import React, { useState, useEffect } from "react";
import URI from "urijs";
import Cookies from "js-cookie";
import { Store } from "../store";
import { Login, ResetPassword } from "../components/home";
import useTranslation from "../hooks/useTranslation";
import useNotification from "../hooks/useNotification";
import { getConfigProperty } from "../utils";
import { Config } from "../__generated__/graphql";

export default function Maintenance({ config, children }: { config: Config; children: any }) {
  const cookieName = `maintenancePassword-${config.id}`;
  const isFromBackoffice = new URI(window.location.href).search(true).from === "backoffice";
  const previewPassword = new URI(window.location.href).search(true).preview;
  const [input, setInput] = useState("");
  const passwordFromCookies = Cookies.get(cookieName);
  const [unlocked, setUnlocked] = useState(!getConfigProperty(config, "information", "maintenanceMode"));
  const [phrase] = useState(getConfigProperty(config, "information", "maintenanceModeNotice") || "Coming soon...");
  const { t } = useTranslation();
  const comparePwd = (pwd: string) => {
    return pwd === getConfigProperty(config, "information", "maintenancePassword");
  };

  useEffect(() => {
    if (!unlocked) {
      if ((previewPassword && comparePwd(previewPassword)) || isFromBackoffice) {
        Cookies.set(cookieName, previewPassword, { expires: 365 });
        setUnlocked(true);
      } else if (passwordFromCookies && comparePwd(passwordFromCookies)) setUnlocked(true);
    }
  }, [config, previewPassword, passwordFromCookies]);

  const handleSubmitMaintenancePassword = (e: any) => {
    e.preventDefault();
    if (comparePwd(input)) {
      setUnlocked(true);
      Cookies.set(cookieName, input, { expires: 365 });
    }
  };

  if (unlocked) return children;
  else
    return (
      <div id="maintenance">
        <h1>{getConfigProperty(config, "information", "shopName")}</h1>
        <p>{phrase}</p>
        <form id="login" onSubmit={handleSubmitMaintenancePassword}>
          <label>
            {t("Have a preview password?")}
            <input type="password" name="password" onChange={e => setInput(e.target.value)} required placeholder="Enter password..." />
          </label>
        </form>
      </div>
    );
}

export const PrivateBrowsing = ({ config }: { config: Config }) => {
  const { addNotification } = useNotification();
  const { t } = useTranslation();
  const { login, isMobile } = Store.useState(s => s);
  const [state, setState] = useState("login");
  const message = config.eshop?.preferences?.access?.private?.message;
  const icon = getConfigProperty(config, "designs", "squareSVG") || getConfigProperty(config, "designs", "logoImage");

  return (
    <div id="home" className={`privateAccess ${isMobile ? "mobile" : ""}`}>
      <div className="logo">{icon ? <img src={icon} /> : null}</div>
      {message ? (
        <div className="message">
          <h2 dangerouslySetInnerHTML={{ __html: message.replace("\n", "<br/>") }}></h2>
        </div>
      ) : null}
      <div className="form">
        {state === "login" ? (
          <Login noRegister={true} t={t} login={login} setState={setState} addNotification={addNotification} config={config} />
        ) : null}
        {state === "resetPassword" ? (
          <>
            <ResetPassword addNotification={addNotification} setState={setState} />
            <button type="button" className="back" onClick={() => setState("login")}>
              {t("Cancel")}
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};
