import { useEffect, useCallback } from "react";
import clone from "clone";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import Cookies from "js-cookie";
import base64 from "base-64";
import { Store } from "./store";
import useNotification from "./hooks/useNotification";
import { apolloClient } from "./apolloClient";
import ReactGA4 from "react-ga4";
import { GET_SESSION, POST_LOGIN, POST_LOGOUT, POST_REGISTER, POST_VERIFY } from "./graphql/queries/user";

const sessionContext = (props: any) => {
  const { checkout } = Store.useState(s => s);

  const { addNotification } = useNotification();
  const client = useApolloClient();

  const { data: sessionData } = useQuery(GET_SESSION);
  const [login] = useMutation(POST_LOGIN);
  const [logout] = useMutation(POST_LOGOUT);
  const [register] = useMutation(POST_REGISTER);
  const [verify] = useMutation(POST_VERIFY);

  const resetStore = useCallback(async () => {
    client.resetStore();
  }, [apolloClient]);

  useEffect(() => {
    if (sessionData) {
      Store.update(s => {
        s.session = clone(sessionData.session);
      });
      if (sessionData.session?.jwt) {
        Cookies.set("jwt", sessionData.session.jwt, { expires: 180 });
        ReactGA4.set({ userId: sessionData.session.user._id });
      } else Cookies.remove("jwt");
    }
  }, [sessionData]);

  const loginHook = useCallback(async ({ password, email }: { password: string; email: string }) => {
    const { data } = await login({ variables: { email: base64.encode(email), password: base64.encode(password), checkoutId: checkout?.id } });
    if (data?.login?.session) {
      const session = data.login.session;
      Cookies.set("jwt", session.jwt, { expires: 180 });
      ReactGA4.set({ userId: session.user._id });
      Store.update(s => {
        s.session = clone(session);
      });
      resetStore();
      return session;
    }
  }, []);

  const verifyHook = useCallback(async ({ code, checkoutId }: { code: number; checkoutId: string }) => {
    const { data } = await verify({ variables: { code, checkoutId } });
    if (data?.userVerify?.session) {
      const session = data.userVerify.session;
      Cookies.set("jwt", session.jwt, { expires: 180 });
      Store.update(s => {
        s.session = clone(session);
      });
      // addNotification({ ok: 1, message: data.message });
      return data.userVerify;
    }
  }, []);

  const logoutHook = useCallback(async () => {
    try {
      await logout({ variables: { checkoutId: checkout?.id } });
      Cookies.remove("jwt");
      resetStore();
    } catch (error: any) {
      addNotification({ ok: 0, message: error.message });
    }
  }, []);

  const registerHook = useCallback(async ({ email, password, firstName, lastName, withVerificationCode }: { email: string; password: string; firstName: string; lastName: string; withVerificationCode: boolean }) => {
    const { data } = await register({
      variables: {
        email: base64.encode(email),
        password: base64.encode(password),
        withVerificationCode,
        firstName,
        lastName
      }
    });
    if (data?.register) {
      return { message: data.register.message };
    }
  }, []);

  useEffect(() => {
    Store.update(s => {
      s.login = loginHook;
      s.logout = logoutHook;
      s.register = registerHook;
      s.verify = verifyHook;
      s.resetStore = resetStore;
    });
  }, []);

  return props.children;
};

export default sessionContext;
