import React from "react";
import { round } from "../utils";
import { Store } from "../store";
import { Config, Listing } from "../__generated__/graphql";

export default function Price({ listing, className, value }: { listing?: Listing; className?: string; value?: number }) {
  const config = Store.useState(s => s.config) as Config;
  const symbol = config.currencySymbol;
  const position = config.currencySymbolPosition;
  const showPricesBeforeTaxes = config.taxes?.rules?.showPricesBeforeTaxes;
  const valueToDisplay = round((listing ? (showPricesBeforeTaxes ? listing.prices.beforeTaxes : listing.prices.sale) : value) || 0);

  return (
    <span className={`price ${className || ""}`}>
      {position === "before" ? (
        <>
          {symbol}
          {valueToDisplay}
        </>
      ) : (
        <>
          {valueToDisplay}
          {symbol}
        </>
      )}
    </span>
  );
}
