import { useEffect, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Store } from "./store";
import clone from "clone";
import Cookies from "js-cookie";
import useNotification from "./hooks/useNotification";
import {
  ADD_VOUCHER,
  GET_CHECKOUT,
  POST_CHECKOUT,
  POST_CHECKOUT_UPDATE,
  POST_CHECKOUT_UPDATE_ITEMS,
  POST_VALIDATE_VOUCHER,
  REMOVE_VOUCHER
} from "./graphql/queries/checkout";
import { Checkout } from "./__generated__/graphql";

export default (props: any) => {
  const { checkout, updateAndSetCheckout } = Store.useState(s => s);
  const { addNotification } = useNotification();
  const storedId = Cookies.get("checkout-id");

  const [getCheckout, { error, data: checkoutData }] = useLazyQuery(GET_CHECKOUT);
  const [createCheckout, { called: createCheckoutCalled }] = useMutation(POST_CHECKOUT);
  const [updateCheckout] = useMutation(POST_CHECKOUT_UPDATE);
  const [updateCheckoutItems] = useMutation(POST_CHECKOUT_UPDATE_ITEMS);
  const [validateVoucher] = useMutation(POST_VALIDATE_VOUCHER);
  const [addVoucher] = useMutation(ADD_VOUCHER);
  const [removeVoucher] = useMutation(REMOVE_VOUCHER);

  const setCheckout = (checkout: Checkout) =>
    Store.update(s => {
      s.checkout = clone(checkout);
    });

  const createNewCheckout = useCallback(async () => {
    try {
      const { data } = await createCheckout();
      if (data?.checkoutCreate) {
        Cookies.set("checkout-id", data.checkoutCreate.id, { expires: 365 });
        setCheckout(data?.checkoutCreate);
      }
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  }, [createCheckout, addNotification]);

  useEffect(() => {
    if (storedId) getCheckout({ variables: { id: storedId } });
  }, [storedId, getCheckout]);

  useEffect(() => {
    if ((error || !storedId) && !createCheckoutCalled) createNewCheckout();
  }, [error, createNewCheckout, createCheckoutCalled, storedId]);

  useEffect(() => {
    const checkout = checkoutData && checkoutData.checkout;
    if (checkout) setCheckout(checkout);
  }, [checkoutData]);

  useEffect(() => {
    Store.update(s => {
      s.addVoucher = addVoucher;
      s.removeVoucher = removeVoucher;
      s.createNewCheckout = createNewCheckout;
    });
  }, [updateCheckout, removeVoucher, addVoucher, checkout, createNewCheckout]);

  useEffect(() => {
    Store.update(s => {
      s.updateBasket = async (listingId: number) => {
        try {
          if (!checkout) throw new Error("Invalid checkout");
          const items = checkout.items.map(i => ({
            listingId: i.listing.id,
            quantity: i.quantity
          }));
          const index = items.findIndex(i => i.listingId === listingId);
          if (index > -1) items.splice(index, 1);
          else items.push({ listingId, quantity: 1 });
          const { data } = await updateCheckoutItems({ variables: { id: checkout.id, items } });
          if (data?.checkoutUpdateItems) {
            setCheckout(data.checkoutUpdateItems);
            addNotification({ ok: 1, message: "Basket updated" });
          }
        } catch (e: any) {
          if (e.toString() === "Error: Checkout not found") createNewCheckout();
          addNotification({ ok: 0, message: e.message });
        }
      };

      s.checkoutUpdateQuantity = async (listingId, quantity) => {
        try {
          if (!checkout) throw new Error("Invalid checkout");
          const items = checkout.items.map(i => ({
            listingId: i.listing.id,
            quantity: i.quantity
          }));
          const index = items.findIndex(i => i.listingId === listingId);
          if (index === -1) return addNotification({ ok: 0, message: "Basket entry not found" });
          if (quantity <= 0) items.splice(index, 1);
          else items[index].quantity = quantity;
          const { data } = await updateCheckoutItems({ variables: { id: checkout.id, items } });
          if (data?.checkoutUpdateItems) {
            setCheckout(data?.checkoutUpdateItems);
            return data?.checkoutUpdateItems;
          }
        } catch (e: any) {
          if (e.toString() === "Error: Checkout not found") createNewCheckout();
          addNotification({ ok: 0, message: e.message });
        }
      };
    });
  }, [addNotification, checkout, updateCheckoutItems, createNewCheckout]);

  useEffect(() => {
    Store.update(s => {
      s.addVoucher = async (id: string) => {
        try {
          if (!checkout) throw new Error("Invalid checkout");
          await validateVoucher({ variables: { id: checkout.id, voucherId: id } });
          const { data } = await addVoucher({ variables: { id: checkout.id, voucherId: id } });
          return data;
        } catch (e: any) {
          return { ok: 0, message: e.message };
        }
      };

      s.removeVoucher = async () => {
        if (!checkout) throw new Error("Invalid checkout");
        await removeVoucher({ variables: { id: checkout.id } });
      };
    });
  }, [checkout, validateVoucher, addVoucher, removeVoucher, updateAndSetCheckout]);

  if (checkout) return props.children;
  else return null;
};
