import { gql } from "../../__generated__/gql";

gql(`
  fragment SessionFields on Session {
    user {
      ...BuyerFields
    }
    jwt
    expires
  }
`);

export const GET_SESSION = gql(`
  query session{
    session {
      ...SessionFields
    }
}`);

export const POST_LOGIN = gql(`
  mutation login($password: String!, $email: String!, $checkoutId: String) {
    login(password: $password, email: $email, checkoutId: $checkoutId) {
      session {
        ...SessionFields
      }
      sessionsCount
      checkout {
        ...CheckoutFields
      }
    }
}`);

export const POST_LOGOUT = gql(`
  mutation logout($checkoutId: String) {
    logout(checkoutId: $checkoutId) {
      ok
      checkout {
        ...CheckoutFields
      }
    }
  }
`);

export const POST_VERIFY = gql(`
  mutation userVerify($token: String, $code: Int, $checkoutId: String) {
    userVerify(token: $token, code: $code, checkoutId: $checkoutId) {
      session {
        ...SessionFields
      }
      message
      checkout {
        ...CheckoutFields
      }
    }
  }
`);

export const POST_REGISTER = gql(`
  mutation register($password: String!, $email: String!, $firstName: String!, $lastName: String!, $withVerificationCode: Boolean) {
    register(password: $password, email: $email, firstName: $firstName, lastName: $lastName, withVerificationCode: $withVerificationCode) {
      message
    }
}`);

export const POST_LIST_OPTIN = gql(`
  mutation listOptin($listRef: ID!, $email: String!) {
    listOptin(listRef: $listRef, email: $email)
  }
`);

export const POST_LIST_OPTOUT = gql(`
  mutation listOptout($listRef: ID!, $email: String!, $reason: String!) {
    listOptout(listRef: $listRef, email: $email, reason: $reason)
  }
`);

export const POST_PASSWORD_USER_SESSION = gql(`
  mutation userVerifyPasswordToken($token: String!) {
    userVerifyPasswordToken(token: $token) {
      ...BuyerFields
    }
}`);

export const POST_USER_CREATE_PASSWORD_TOKEN = gql(`
  mutation userCreatePasswordToken($email: String!, $withVerificationCode: Boolean) {
    userCreatePasswordToken(email: $email, withVerificationCode: $withVerificationCode)
}`);

export const POST_USER_PWD_RESET = gql(`
  mutation userPasswordReset($password: String!, $token: String!) {
    userPasswordReset(password: $password, token: $token)
}`);

export const POST_USER_UPDATE = gql(`
  mutation userUpdate($email: String, $password: String, $firstName: String, $lastName: String, $telephone: String, $organisation: String, $taxNumber: String, $addresses: [AddressInput], $preferences: JSON) {
    user: userUpdate(email: $email, password: $password, firstName: $firstName, lastName: $lastName, telephone: $telephone, organisation: $organisation, taxNumber: $taxNumber, addresses: $addresses, preferences: $preferences) {
      ...BuyerFields
    }
}`);

export const GET_USER = gql(`
  query user{
    user {
      ...BuyerFields
    }
  }
`);

export const POST_BUYER_DEACTIVATE = gql(`
  mutation buyerDeactivate{
    buyerDeactivate
  }
`);

export const GET_COUNTRIES = gql(`
  query countries{
    countries
  }
`);
